const words = {
  growth_stage_of_crop: 'Growth Stage of Crop',
  growth_stage_of_animal: 'Growth Stage of Livestock',
  type_of_pest: 'Type of Pest',
  type_of_disease_helper: 'Type of Crop Disease Helper',
  type_of_disease: 'Type of Crop Disease',
  type_of_weed_helper: 'Type of Weed Helper',
  type_of_weed: 'Type of Weed',
  registration_year_helper: 'Registration Year Helper',
  registration_year: 'Registration Year',
  registration_number_helper: 'Registration Number Helper',
  registration_number: 'Registration Number',
  most_recent_registration_renewal_date_helper:
    'Most Recent Registration Renewal Date Helper',
  most_recent_registration_renewal_date:
    'Most Recent Registration Renewal Date',
  invoice: 'Invoice',
  update_invoice: 'Update invoice',
  begin_delivery: 'Begin delivery',
  update_invoice_success: 'Update invoice success',
  update_invoice_error: 'Update invoice error',
  dialog_confirm_delivery:
    'Please confirm you have received the payment in full before delivery',
  confirm_delivery_success: 'Confirm delivery success',
  confirm_delivery_error: 'Confirm delivery error',
  delivery_terms: 'Delivery Term',
  delivery_terms_desc:
    'This order shall be delivered by seller to the buyer within 2 to 5 days',
  payment_terms: 'Payment Terms',
  payment_terms_1: 'This order must be pre paid',
  payment_terms_2: 'This order must be paid with cash on delivery',
  payment_terms_3: 'This order must be paid later according to credit terms',
  payment_terms2_note:
    'Please make sure the appointed courier offers Cash on Delivery service',
  reject_order_note: 'Are you sure you want to reject this order?',
  rule:
    'I acknowledge that Agrimomo will not under, any circumstances, be liable for consequential, incidental, special, punitive or exemplary damages arising out of or related to this transaction including lost profits or loss of business.',
  pre_paid: 'This order is required to be pre-paid before delivery',
  wait_for_confirm: 'Wait For Buyer Feedback',
  cash_on_delivery: 'This order is required to be paid with cash on delivery',
  note_cash_on_delivery:
    'Please make sure the appointed courier offers Cash on Delivery service',
  credit_term_title:
    'This order can be paid later with the following credit terms',
  note_credit_term:
    'This means {credit_term_discount}% discount if paid within {credit_term_period} day(s) from order date. If not, the full payment shall be made within {payment_due_in_days} day(s) from order date.',
  wholesale_img: 'Upload product picture in wholesale unit',
  prod_img: 'Upload product picture in retail unit',
  check_blank: 'Please fill all the red box',
  check_price: 'Please check the price in the red box',
  check_image:
    'Please upload both picture in retail unit and picture in wholesale unit',
  expiry_list_batch_number_helper: 'Batch Number',
  expiry_list_batch_number: 'Batch Number',
  contact_person_name_eng: 'Contact Person Name (English)',
  seller_name_local: 'Seller Name (Local Language)',
  seller_name_eng: 'Seller Name (English)',
  contact_person_name_local: 'Contact Person Name (Local Language)',
  contact_person_position: 'Contact Person Position',
  contact_person_profile_picture: 'Contact Person Profile Picture',
  address: 'address',
  seller_brand_logo: 'Seller Brand Logo',
  copy_of_id_card_pass_port:
    'Copy of ID card or passport of authorized representative + signed by authorized representative',
  copy_of_bank_account_book:
    'Copy of bank account book + signed by authorized representative',
  copy_of_source_document:
    'Copy of product source document e.g. purchase and sales contract, sales agent appointment contract, manufacturing contract, factory setup license, or import clearance letter by relevant authorities (optional)',
  copy_of_company_affidavit:
    'Copy of company affidavit (not over 3 months) + signed by authorized representative',
  copy_of_value_added_tax:
    'Copy of value added tax (optional) + signed by authorized representative',
  copy_of_power_of_attorney: 'Copy of power of attorney (optional)',
  hear_about_agrimomo: 'How did you hear about Agrimomo?',
  delete: 'delete',
  max_file_size_upload_title: 'Maximium 5 files, 5 mb per file',
  form_product_cancel_confirm: 'You will lose all unsaved data. Are you sure?',
  credit_term_offered: 'Credit Term Offered',
  check_file: 'Please upload all required file',
  discount_percent: 'Credit Term Discount %',
  max_discount_day: 'Max Discount Day (From Order Date)',
  max_pay_day: 'Max Pay Day (From Order Date)',
  type_of_animal: 'Type of Livestock',
  type_of_animal_disease: 'Type of Livestock Diseases',
  general_description: 'General Buyer Description',
  annual_revenue: 'Annual Revenue',
  annual_revenue_helper:
    'Total annual revenue including all online and offline',
  total_target_sales_on_amm_per_month:
    'Total Target Sales on Agrimomo (Per Month)',
  total_target_fertilizer: 'Total Target Sales for Fertilizer',
  total_target_fertilizer_helper: 'Total Target Sales for Fertilizer Helper',
  total_target_agro_chem: 'Total Target Sales for Agrochemical',
  total_target_seed: 'Total Target Sales for Seed',
  total_target_seed_helper: 'Total Target Sales for Seed Helper',
  total_target_animal_feed: 'Total Target Sales for Livestock',
  total_target_animal_feed_helper: 'Total Target Sales for Livestock Helper',
  total_target_animal_machinary_tools: 'Total Target Sales for Agri Tools',
  total_target_machinery_tools_helper:
    'Total Target Sales for Agri Tools Helper',
  pre_pay: 'Pre Pay',
  cod: 'Cash on Delivery',
  after_30_days: '30 Days from Order Date',
  after_45_days: '45 Days from Order Date',
  after_60_days: '60 Days from Order Date',
  after_90_days: '90 Days from Order Date',
  after_120_days: '120 Days from Order Date',
  after_180_days: '180 Days from Order Date',
  after_365_days: '365 Days from Order Date',
  other_term_offer: 'Other Terms Offered',
  other_term_offer_helper: 'Other Terms Offered Helper',
  commision_model: 'Commission Model',
  all_transaction_commission: 'All Transaction Commission',
  only_new_customer_commission:
    'Only New Customer Commission (calculation is based only on new customers, excluding existing customers as declared upon signup, reverted to all commission after 1 year from sign up date)',
  delivered_by_seller_3PL_appointed:
    'Delivered by Seller or 3PL Appointed by Seller',
  total_no_of_customers: 'Total Number of Existing Customers',
  list_new_customers: 'Add Existing Customers',
  min_day_delivery: 'Min Delivery Days',
  max_day_delivery: 'Max Delivery Days',
  type_of_seller: 'Type of Sellers',
  individual: 'Individual',
  juristic: 'Juristic Person',
  manufacturer_seller_activities: 'Manufacturer',
  distributor_seller_activities: 'Distributor',
  importer_seller_activities: 'Importer',
  hear_on_web_app: 'Agrimomo website/app',
  hear_on_social_media: 'Social media',
  hear_on_word_of_mouth: 'Word of mouth',
  hear_on_local_authorities:
    'Local authorities (e.g. Department of Agriculture, cooperatives, etc.)',
  submitted_time: 'Submitted time',
  seller_time_submitted: 'Seller Submitted Time',
  amm_time_submitted: 'Agrimomo Submitted Time',
  farmshop_name_eng: 'Farm Shop Name (English)',
  farmshop_name_local: 'Farm Shop Name (Local Language)',
  farmshop_owner_name_eng: 'Farm Shop Owner Name (English)',
  farmshop_owner_name_local: 'Farm Shop Owner Name (Local Language)',
  farmshop_type: 'Type of Farm Shops',
  manufacture: 'Manufacturer',
  reseller: 'Reseller',
  farmshop_mobile: 'Mobile Number',
  farmshop_email: 'Email',
  farmshop_house_number: 'House Number',
  farmshop_street_name: 'Street',
  farmshop_village_name: 'Village ',
  farmshop_sub_district_name: 'Sub District',
  farmshop_district_name: 'District',
  farmshop_province: 'Province',
  farmshop_country: 'Country',
  farmshop_post_code: 'Post Code',
  farmshop_google_map: 'Google Map Link',
  farmshop_picture: 'Farm Shop Picture',
  farmshop_description: 'General Farm Shop Description',
  farmshop_feature_category: 'Featured Category',
  farmshop_agent_level: 'Agent Level',
  overview: 'Overview',
  delivery_time: 'Delivery Time',
  documents: 'Documents',
  survey: 'Survey',
  financial_section: 'Financial Section',
  product_section: 'Product Section',
  continue_later: 'Continue Later',
  submit: 'Submit',
  trader_seller_activities: 'Trader',
  province: 'Province',
  sub_district: 'Sub District/Ward',
  seller_activities: 'Seller Activities',
  time_submitted: 'Seller Time Submmited',
  time_update: 'Seller Time Updated',
  amm_time_verified: 'AMM Time Verified',
  amm_time_reject: 'AMM Time Rejected',
  other_term_offered: 'Other Term Offered',
  other_term_offered_helper: ' ',
  min_day_delivery_helper: ' ',
  max_day_delivery_helper: ' ',
  discount_percent_helper: ' ',
  max_discount_day_helper: ' ',
  max_pay_day_helper: ' ',
  seller_name_eng_helper: ' ',
  general_seller_description_helper: ' ',
  seller_name_local_helper: ' ',
  contact_person_name_eng_helper: ' ',
  contact_person_name_local_helper: ' ',
  contact_person_position_helper: ' ',
  address_helper: ' ',
  email_helper: ' ',
  phone_number_helper: ' ',
  total_target_animal_machinary_tools_helper: ' ',
  farmshop_name_eng_helper: ' ',
  farmshop_name_local_helper: ' ',
  farmshop_owner_name_eng_helper: ' ',
  farmshop_owner_name_local_helper: ' ',
  farmshop_mobile_helper: ' ',
  farmshop_email_helper: ' ',
  farmshop_house_number_helper: ' ',
  farmshop_street_name_helper: ' ',
  farmshop_village_name_helper: ' ',
  farmshop_sub_district_name_helper: ' ',
  farmshop_district_name_helper: ' ',
  farmshop_province_helper: ' ',
  farmshop_country_helper: ' ',
  farmshop_post_code_helper: ' ',
  farmshop_google_map_helper: ' ',
  farmshop_description_helper: ' ',
  seller_kyc: 'Seller KYC',
  last_updated_date: 'Last updated',
  compliance_section: 'Compliance Section',
  date: 'Transaction Date',
  sub_product_type: 'Sub Product Type',
  crop: 'Crop',
  animal: 'Livestock ',
  kyc: 'Seller KYC',
  sign_in_des: 'Distribution Management System',
  term_of_use: 'Term and condition and privacy policy',
  i_have_accept: 'I have accepted the',
  privacy_policy: 'Privacy Policy',
  seller_agreement: 'Seller Agreement',
  term_and_condition: 'Term and Condition',
  sign_up: 'Sign Up',
  user_existed: 'User Existed',
  soft_delete: 'Soft Delete',
  wait_5_mins: 'Wait 5 mins for next SMS',
  wrong_username_format: 'Please insert correct phone number',
  wrong_code: 'Wrong OTP code',
  create_price_by_gps: 'Create Price by Location',
  created: 'Created',
  already_existed: 'Already Existed',
  price_gps_deleted: 'Deleted',
  location_updated: 'Updated',
  create_farm_shop: 'Create',
  agent_existed: 'Already Existed',
  create_account_success: 'Successfully created!',
  relation_updated: 'Updated',
  no_properties: 'No medicinal properties',
  prod_updated: 'Updated',
  prod_created: 'Created',
  distribution_management: 'Distribution Management System (DMS)',
  order_price: 'Order Price',
  logistic_fee_image: 'Quotation',
  logistic_fee_number: 'Logistic Fee',
  user_order_name: 'Username',
  order_id: 'Order ID',
  bank_transfer_image: 'Bank transfer image',
  update_logistic_fee: 'Update quotation',
  and: 'and',
  me: 'me',
  price_by_gps_des:
    'Remark: Price By Location overrides Current Price for the selected location(s). However, it is still subject to Agent Discount as set up in the Product Management menu.',
  list_province_required: 'list province is required',
  admin: 'Admin',
  change_pass_des: 'New Password is the OTP code.',
  verify_number: 'Get New Password',
  forgot_password: 'Forgot password?',
  change_pass_error: 'Input Wrong',
  animal_food: 'Livestock ',
  select_language: 'Select Language',
  management: 'Management',
  product: 'Product',
  product_management: 'Product Management',
  all: 'All',
  fertilizer: 'Fertilizer',
  chemical: 'Agrochemical',
  seed: 'Seed',
  animal_feed: 'Livestock ',
  tool: 'Agri Tools',
  other: 'Others',
  local_name: 'Product Name',
  status: 'Status',
  price: 'Price',
  original_price: 'Original Price',
  inventory_num: 'Inventory Number',
  expiry_date: 'Expiry Date',
  action: 'Action',
  create_product: 'Create Product',
  upload_by_excel: 'Upload by Excel',
  product_code: 'Product Code',
  product_code_helper: 'SKU',
  ean: '13 Digit EAN (if applicable)',
  ean_helper: '13 Digit Number',
  local_name_english: 'English Name',
  local_name_helper: 'Local Name Helper',
  category: 'Categories',
  sub_category: 'Sub Categories',
  phone_number: 'Mobile Number',
  password: 'Password',
  login_false: 'Sign In Failed',
  login_success: 'Sign In Success',
  update_product_id: 'Update Product: {id}',
  update_product: 'Update Product',
  overview_title: 'Super Admin',
  hello_mes: 'Dashboard',
  good_morning: 'Hello',
  filter_date: 'Filter Date',
  filter_button: 'Filter',
  total_sell_quantity: 'Total Sell Quantity',
  total_sales: 'Total Sales',
  dashboard: 'Dashboard',
  product_name: 'Product Name',
  buyer_name: 'Buyer Name',
  total_price: 'Total Price',
  total_quantity: 'Total Quantity',
  see_all: 'See All',
  product_create_success: 'Created Successful',
  product_update_success: 'Updated Successful',
  product_update_error: 'Updated Failed',
  product_create_error: 'Created Failed',
  max_image_upload: 'You can only upload {maxLength} files',
  max_file_size_upload:
    'You can only upload a max file size of {maxFileSize} MB',
  image_upload_error: 'Image Upload Error',
  select_file: 'Select File',
  sku: 'SKU',
  sku_helper:
    'SKU must be unique and is for your own reference only as it is not the system-assigned number',
  product_local_name: 'Product Name',
  product_local_name_helper: 'Product Name Helper',
  product_eng_name: 'Product name in English',
  product_eng_name_helper: 'Product name in English Helper',
  product_common_name: 'Product Common Name',
  product_common_name_helper: 'Product Common Name Helper',
  product_manufacturer_name: 'Product Manufacturer Name',
  product_manufacturer_name_helper: 'Product Manufacturer Name Helper',
  product_importer_name: 'Product Importer Name',
  product_importer_name_helper: 'Product Importer Name Helper',
  product_distributor_name: 'Product Distributor Name',
  product_distributor_name_helper: 'Product Distributor Name Helper',
  product_type: 'Type',
  brand_name: 'User Token',
  brand_name_helper: 'Brand name Helper',
  origin: 'Origin',
  origin_helper: 'Country of Origin',
  unit_type: 'Unit type',
  general_des: 'General Description',
  general_des_helper: 'General Description Helper',
  tech_des: 'Technical Description',
  tech_des_helper: 'Technical Description Helper',
  manual_guide: 'How to Use',
  manual_guide_helper: 'How to Use',
  inventory_num_helper: 'Quantity',
  price_helper: 'Price inclusive of VAT',
  price_exclude_vat_helper: 'Price exclusive of VAT',
  curreny: 'Currency',
  curreny_helper: 'Currency is set automatically',
  note: 'Note',
  note_helper: 'Note Helper',
  on_hand_date: 'On Hand Date',
  on_hand_date_helper: 'On Hand Date',
  expiry_list_expridate: 'Expiry Date',
  expridate_helper: 'Expiry Date',
  expiry_list_quantity: 'Expiry List Quantity',
  expiry_list_quantity_helper: 'Expiry List Quantity Helper',
  warranty_note: 'Warranty Note',
  warranty_note_helper: 'Warranty Note Helper',
  status_active: 'Active',
  status_inactive: 'Inactive',
  vat: 'VAT',
  yes: 'Yes',
  no: 'No',
  group_1_price: 'Price for Group 1',
  group_1_price_helper: 'Discount Price for Group 1',
  group_1_quantity: 'Group 1 Quantity',
  group_1_quantity_helper: 'Total Quantity for Group 1',
  group_2_price: 'Price for Group 2',
  group_2_price_helper: 'Discount Price for Group 2',
  group_2_quantity: 'Group 2 quantity',
  group_2_quantity_helper: 'Total Quantity for group 2',
  group_3_price: 'Price for group 3',
  group_3_price_helper: 'Discount price for group 3',
  group_3_quantity: 'Group 3 quantity',
  group_3_quantity_helper: 'Total Quantity for group 3',
  agent_level_1: 'Level 1',
  agent_level_1_helper: 'Discount % for Agent Level 1',
  agent_level_2: 'Level 2',
  agent_level_2_helper: 'Discount % for Agent Level 2',
  agent_level_3: 'Level 3',
  agent_level_3_helper: 'Discount % for Agent Level 3',
  agent_level_4: 'Level 4',
  agent_level_4_helper: 'Discount % for Agent Level 4',
  agent_level_5: 'Level 5',
  agent_level_5_helper: 'Discount % for Agent Level 5',
  cancel: 'Cancel',
  product_delete_confirm: 'Comfirm Delete',
  load_more: 'Load More',
  order_management: 'Order Management',
  order: 'List',
  inquiry_created: 'Inquiry Created',
  farm_shop_confirm_order_payments: 'Buyer confirm payment',
  wait_for_update_fee: 'Wait for Seller Feedback',
  dms_reject: 'Seller Reject',
  farm_shop_reject: 'Buyer Reject',
  farmshop_bank_confirm: 'Buyer Confirm Payment',
  wait_for_farmshop_confirm: 'Wait For Buyer Feedback',
  on_progress: 'On Progress',
  confirm_order_success: 'Confirm Success',
  confirm_order_error: 'Confirm Error',
  delivery_order_success: 'Delivery Order Success',
  delivery_order_error: 'Delivery Order Fail',
  confirm: 'Confirm',
  delivery: 'Delivery',
  order_by: 'Order By',
  more: 'More',
  farm_shop_management: 'Agent Management',
  farm_shop: 'Agent List',
  create_farm_shop_account: 'Create Agent Account',
  farm_name: 'Agent name',
  username: 'Mobile Number',
  agent_level: 'Level',
  total_order: 'Total Sales',
  last_order: 'Last Order Date',
  order_date: 'Order date',
  avatar_update_false: 'Avatar Update Fail',
  upload_image: 'Upload',
  user_update_success: 'User Update Success',
  user_update_false: 'User Update Fail',
  user_local_name: 'Local Name',
  user_eng_name: 'English Name',
  email: 'Email',
  city: 'Province',
  district: 'District',
  ward: 'Sub District/Ward',
  post_code: 'Post Code',
  main_category: 'Main Product',
  user_general_des: 'General Description',
  video: 'Video',
  add_link_video: 'Video Link',
  images: 'Images',
  bank_account_information: 'Bank Account Information',
  save_change: 'Save',
  update_bank_information: 'Update Bank information',
  bank_name: 'Bank Name',
  branch_name: 'Branch Name',
  account_number: 'Account Number',
  owner_name: 'Account Name',
  ok: 'Ok',
  wholesaler_information: 'Seller Information',
  settings: 'Settings',
  general_setting: 'User Information',
  change_password: 'Change Password',
  password_update_success: 'Password Update Success',
  password_update_false: 'Password Update Fail',
  confirm_password: 'Confirm Password',
  sign_out: 'Sign out',
  sign_in: 'Sign In',
  is_required: 'is required',
  do_not_have_account: 'Don\'t have an account?',
  have_account: 'Have an account?',
  verify_code: 'Verify Code',
  register_false: 'Sign Up Fail',
  register_success: 'Sign Up Success',
  code: 'OTP Code',
  create_account: 'Create Account',
  '404_notice': '401: We are sorry but we are not able to authenticate you.',
  '404_sub_notice':
    'You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation',
  back_home: 'Back to Home',
  copy_to_clipboard: 'Copied to clipboard',
  field_required: 'This field is required',
  price_by_gps_management: 'Price By Location',
  price_by_gps: 'Location Price',
  list_province: 'List Provinces',
  gps_price: 'Selected Location Price',
  gps_price_delete_confirm: 'Are you sure to delete this?',
  please_select: 'Please choose 1 option',
  image_upload_max_file_size_error: 'File size must be less than 5 MB',
  product_id: 'Product ID',
  price_helper_validate_price: 'Price must be smaller than List Price',
  group_1_price_helper_validate_price:
    'Price must be smaller than Current Price',
  group_2_price_helper_validate_price:
    'Price must be smaller than Group 1 Price',
  group_3_price_helper_validate_price:
    'Price must be smaller than Group 2 Price',
  group_1_quantity_helper_validate_price:
    'Quantity must be greater than Minimum Order Quantity',
  group_2_quantity_helper_validate_price:
    'Quantity must be greater than Group 1 Quantity',
  group_3_quantity_helper_validate_price:
    'Quantity must be greater than Group 2 Quantity',
  attach_registeration_file: 'Maximium 5 files, 5 mb per file',
  attach_file: 'Attach registration file',
  sku_existed: 'SKU already Existed',
  min_order_quantity: 'Min of Order Quantity',
  min_order_quantity_helper: 'Min of Order Quantity Helper',
  original_price_helper: 'List Price must be greater than Current Price',
  credit_term_helper: 'Credit Term must be integer',
  credit_term: 'Credit Term',
  cost_price_helper: 'Cost Price of Product Helper',
  cost_price: 'Cost Price of Product',
  product_private: 'Is this product visible only to selected agents?',
  organic_product: 'Is this an organic product?',
  crop_or_animal: 'Select Crop or Livestock',
  type_of_crop: 'Type of Crop',
  type_of_crop_helper: 'Insert Type of Crop',
  prod_deleted: 'Deleted',
  brand_name_1: 'Brand name',
  change_pass_success: 'Successful',
  street_address: 'Address',
  watch_how_to_vdo: 'Watch “Become a Seller” vdo',
  mode_of_action_helper: ' ',
  add: 'Add',
  new_option: 'New option',
  mode_of_action: 'Mode of Action',
  contact_person_first_name_english: 'Contact Person First Name (English)',
  contact_person_last_name_english: 'Contact Person Last Name (English)',
  contact_person_first_name_english_helper:
    'Contact Person First Name (English) Helper',
  contact_person_last_name_english_helper:
    'Contact Person Last Name (English) Helper',
  contact_person_first_name_local: 'Contact Person First Name (Local Language)',
  contact_person_last_name_local: 'Contact Person Last Name (Local Language)',
  contact_person_first_name_local_helper:
    'Contact Person First Name (Local Language) Helper',
  contact_person_last_name_local_helper:
    'Contact Person Last Name (Local Language) Helper',
  birth_date: 'Birth date',
  birth_date_helper: 'Birth Date',
  identity_id: 'Identity ID',
  identity_id_helper: 'Identity ID',
  nationality: 'Nationality',
  postcode: 'Postcode',
  occupation_type: 'Occupation Type',
  individual_type: 'Individual Type',
  tax_id: 'Tax ID',
  verify: 'Verify',
  address_no: 'Address number',
  submit_step1: 'Step1: Please submit the information',
  submit_step2:
    'Step2: Please click "Confirm Seller KYC" button to complete seller payment verification',
  phone_number_validate:
    'This field is required and the format should be "+66123456789"',
  identity_id_validate: 'This field is required and should have 13 number',
  birth_date_validate:
    'This field is required and should be older than 20 years and younder than 120 years',
  get_otp: 'Get OTP',
  error_response: 'Something went wrong. Please try late.',
  opt_send: 'OPT has been sent!',
  tax_invoice_receipt: 'Tax Invoice/Receipt from Agrimomo (For Seller Only)',
  invoice_from_agrimomo: 'Invoice from Agrimomo (For Seller Only)',
  view: 'View',
  price_excluding_logistic_fee: 'Price excluding logistic fee',
  price_including_logistic_fee: 'Price including logistic fee',
  extra_discount: 'Extra discount',
  net_price: 'Net price',
  delivery_term_update_success: 'Update delivery term success',
  delivery_term_update_error: 'Update delivery term error',
  occupation_code: 'Occupation code',
  profession_code: 'Profession code',
  total_target_agro_chem_helper: ' ',
  discount_description:
    'Extra discount shall not exceed 90% of "Price excluding logistic fee" and may be applied at sole discretion of the seller from price negotiation with the buyer via Agrimomo\'s Chat system. Hence, this shall not reduce the marketplace fee, which is calculated from the above "Price excluding logistic fee".',
  address_kyc: 'Road/Building/Village',
  start_time: 'Start Time',
  end_time: 'End Time',
  payment_status: 'Payment status',
  payment_date: 'Payment date',
  active: 'Active',
  completed: 'Completed',
  rejected: 'Rejected',
  search: 'Search',
  reject_order: 'Reject order',
  cod_description_1:
    'In the case of COD, the seller must transfer the fee(s) directly to Agrimomo via Kasikorn Bank, A/C name Agrimomo (Thailand) Co., Ltd., Ratchadapisek Huaykwang Branch, A/C no. 1532677838. ',
  cod_description_2:
    'Upon successful payment, Agrimomo will issue tax invoice/receipt to the seller via email.',
  order_has_been_delivered: 'This order has been delivered',
  buyer_confirm_within: 'Please confirm the order within 2 day(s)',
  credit_term_note:
    '{credit_term_discount}% discount if paid within {credit_term_period} days, payment due in {payment_due_in_days} days',
  net: 'NET',
  logistic_fee_update_success: 'Logistic fee update success',
  logistic_fee_update_error: 'Logistic fee update error',
  update_payment_terms_success: 'Update payment term success',
  update_payment_terms_error: 'Update payment term error',
  kyc_save_success: 'KYC save success',
  kyc_save_error: 'KYC save error',
  kyc_submit_success: 'KYC submit success',
  kyc_submit_error: 'KYC submit error',
  order_placed: 'Order placed',
  seller_confirmed: 'Seller confirmed',
  buyer_confirmed: 'Buyer confirmed',
  delivered: 'The order has been delivered',
  message: 'Message',
  update_begin_delivery: 'Update & Begin delivery',
  buyer_has_agreed_terms: 'The buyer has agreed to the credit terms',
  buyer_agreed_cod: 'The buyer has agreed to pay with cash on delivery',
  order_delivered: 'Delivered',
  receipt: 'Receipt',
  buyer_paid_cod: 'Buyer has paid with cash on delivery',
  'discount_%': 'Discount (%)',
  if_paid_within_days: 'if paid within (days)',
  due_in_days: 'due in (days)',
  due_date: 'Due date',
  credit_terms: 'Credit terms',
  farm_shop_confirm_being_delivered: 'Buyer confirm delivery',
  update_receipt: 'Update receipt',
  to_be: 'to be',
  to_be_cod: 'to be',
  paid_later: 'Paid later',
  with_credit_terms: 'with credit terms',
  with: 'with',
  your_offer_is: 'Your offer is',
  order_rejected: 'This order has been rejected',
  with_the_following_reason: 'with the following reason',
  warning: 'warning',
  this_buyer_pay_later: 'You are about to let this buyer pay later',
  paid: 'Paid',
  why_close_the_sales: 'Why close the sales on Agrimomo?',
  authentic_products: 'Authentic products 100%',
  verified_sellers: 'Verified sellers via the Seller KYC system',
  buyer_protection: 'Buyer protection in case of refund/return',
  dismiss: 'Dismiss',
  reason: 'Reason',
  less_than: 'less than',
  more_than: 'more than',
  verify_otp: 'Confirm Seller KYC',
  bank_remark: 'Remark: Bank account number cannot be changed',
  net_price_remark: 'Remark: Net price must be more than 20 Baht',
  are_you_sure_you_want_to_confirm: 'Are you sure you want to confirm?',
  paid_cod: 'Paid',
  with_cod: 'with',
  seller_name: 'Seller Name',
  general_seller_description: 'General Seller Description',
  feature_category: 'Featured Categories',
  vendors: 'Vendors',
  popular_products: 'Popular Products',
  by: 'By',
  featured_vendors: 'Featured Vendors',
  description: 'Description',
  visit_store: 'Visit Store',
  we_found: 'We found',
  item_for_you: 'item(s) for you!',
  add_to_cart: 'Add to cart',
  availability: 'Availability',
  items_in_stock: 'Items In Stock',
  additional_info: 'Additional Info',
  vendor: 'Vendor',
  related_products: 'Related products',
  no_products_found: 'No Products Found',
  contact_seller: 'Contact Seller',
  your_cart: 'Your Cart',
  there_are: 'There are',
  products_in_your_cart: 'product(s) in your cart',
  clear_cart: 'Clear Cart',
  unit_price: 'Unit Price',
  quantity: 'Quantity',
  subtotal: 'Subtotal',
  remove: 'Remove',
  continue_shopping: 'Continue Shopping',
  cart_totals: 'Cart Totals',
  cart_subtotal: 'Cart Subtotal',
  shipping: 'Shipping',
  free_shipping: 'Free Shipping',
  total: 'Total',
  proceed_to_payment: 'Proceed To Payment',
  proceed_to_order: 'Proceed to order',
  checkout: 'Check Out',
  order_placement: 'Order placement',
  current_user: 'Current User',
  billing_details: 'Billing Details',
  shipping_details: 'Shipping Details',
  your_order: 'Your Order',
  no_product: 'No Products',
  create_order: 'Create Order',
  place_order: 'Place Order',
  account: 'Account',
  cart: 'Cart',
  my_account: 'My Account',
  orders: 'Orders',
  pos: 'POS',
  account_details: 'Account Details',
  total_buy: 'Total Buy',
  revenue: 'Revenue',
  total_buy_quantity: 'Total Buy Quantity',
  in_stock: 'In Stock',
  my_orders: 'My Orders',
  received: 'I have received the order',
  no_order: 'No Orders',
  display_name: 'Display Name',
  email_address: 'Email',
  new_password: 'New Password',
  product_detail: 'Product Detail',
  selling_price: 'Selling Price',
  sold_quantity: 'Sold Quantity',
  expridate: 'Expiry Date',
  update: 'Update',
  login: 'Sign In',
  create_order_validate_blank_false: 'Please fill in all the required fields',
  report: 'Report',
  Vendors_List: 'Vendors List',
  We_have: 'We have',
  product_now: 'product now',
  vendors_now: 'vendors now',
  search_vendor_by_id: 'Search vendors (by name or ID)...',
  amount: 'Amount to be ',
  reject: 'Reject',
  show_banking_info: 'Show banking info',
  pay: 'pay',
  begin_delivery_soon: 'will begin delivery soon',
  dialog_reject: 'Are you sure you want to reject this order',
  dialog_cancel: 'Are you sure you want to cancel this order?',
  confirm_info: 'My shipping information is the same as my billing information',
  brand_name_kyc: 'User Token',
  buyer_name_eng_helper: 'Buyer Name (English) Helper',
  buyer_name_local_helper: 'Buyer Name (Local Language) Helper',
  buyer_name_eng: 'Buyer Name (English)',
  buyer_name_local: 'Buyer Name (Local Language)',
  buyer_brand_logo: 'Buyer Brand Logo',
  total_target_sales_on_amm: 'Total Target Buy on AMM (Per Month)',
  total_target_sales: 'Total Target Buy',
  manufacturer: 'Manufacturer',
  type_of_buyer: 'Type of Buyers',
  retailer_seller_activities: 'Retailer',
  farmer_seller_activities: 'Farmer',
  wholesaler_seller_activities: 'Wholesaler',
  copy_of_authorized_trading:
    'Copy of authorized trading document e.g. fertilizer trading license, agro chemical trading license, seed trading license, animal feed trading license, purchase and sales contract, or sales agent appointment contract (optional)',
  buyer_activities: 'Buyer Activities',
  land_area: 'Land Area',
  update_buyer_kyc_sucessfully: 'Update Buyer KYC Successfully',
  general_description_helper: 'General Description Helper',
  buyer_kyc: 'Buyer KYC',
  common_name: 'Product Common Name',
  discount_info: 'Discount Info',
  eng_name: 'English Name',
  manufacturer_name: 'Manufacturer Name',
  importer_name: 'Importer Name',
  distributor_name: 'Distributor Name',
  kyc_seller: 'Seller KYC',
  seller_management: 'Seller Management',
  buyer_management: 'Buyer Management',
  kyc_seller_management: 'Seller KYC Management',
  kyc_buyer_management: 'Buyer KYC Management',
  mkt_fee_options: 'Marketplace Fee Options',
  seller_user: 'Seller User',
  buyer_user: 'Buyer User',
  product_restore_confirm: 'Confirm Restore?',
  delete_user_confirm: 'Confirm Delete?',
  restore_user_confirm: 'Confirm Restore?',
  delete_buyer_confirm: 'Confirm Delete?',
  restore_buyer_confirm: 'Confirm Restore',
  seller_user_management: 'Seller User Management',
  buyer_user_management: 'Buyer User Management',
  kyc_buyer: 'Buyer KYC',
  fee_management: 'Global Fee Management',
  rate: 'Rate',
  start_date: 'Start date',
  end_date: 'End date',
  added_by: 'Added by',
  buyer_time_submitted: 'Buyer Time Submmited',
  buyer_time_update: 'Buyer Time Updated',
  user_id: 'User id',
  update_time: 'Update time',
  created_time: 'Created Time',
  log: 'Log',
  type: 'Type',
  SellerManagement: 'Seller Management',
  BuyerManagement: 'Buyer Management',
  SellerKYCManagement: 'Seller KYC Management',
  BuyerKYCManagement: 'Buyer KYC Management',
  send_all_chat: 'Send all chat',
  SellerUser: 'Seller',
  Profile: 'Profile',
  user_name: 'User Name',
  buyer_setting: 'Buyer Setting',
  buyer_information: 'Buyer Information',
  buyer_kyc_1: 'Buyer KYC',
  mkt_fee_options_1: 'Global Fee Management',
  seller_information: 'Seller Information',
  view_order_id: 'Order: {id}',
  expired: 'Expired',
  unpaid: 'unpaid',
  back: 'Back',
  kyc_info: 'KYC information',
  user_info: 'User information',
  kyc_update_success: 'KYC update success',
  kyc_update_error: 'KYC update error',
  buyer_kyc_submit_sucessfully: 'Buyer kyc submit successfully',
  dms_cofirm_delivery: 'Seller confirm delivery',
  order_received: 'Order received',
  order_received_paid: 'Order received & paid',
  order_paid: 'This order has been paid',
  order_confirmed_credit_terms:
    'This order has been confirmed with the following credit terms',
  kgp_onboard_status: 'Submitted to Payment Gateway',
  kgp_onboard_submitted: 'Submitted',
  kgp_onboard_not_submitted: 'Unsubmitted',
  category_sales_target_error:
    'At least 1 category must have the sales target.',
  jpg_png: 'Only jpg and png',
  jpg_png_pdf: 'Only jpg, png, and pdf',
  sales_summary_report: 'Sales Summary Report',
  sales_detail_report: 'Sales Detail Report',
  report_year: 'Report Year'
};
export default words;
