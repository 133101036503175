const words = {
  select_language: 'เลือกภาษา',
  management: 'จัดการ',
  product: 'สินค้า',
  product_management: 'จัดการสินค้า',
  all: 'ทั้งหมด',
  fertilizer: 'ปุ๋ย',
  chemical: 'เคมีเกษตร',
  seed: 'เมล็ดพันธุ์',
  animal_feed: 'ปศุสัตว์',
  tool: 'อุปกรณ์เกษตร',
  other: 'อื่นๆ',
  local_name: 'ชื่อสินค้า',
  status: 'สถานะ',
  price: 'ราคา',
  original_price: 'ราคาตั้ง',
  inventory_num: 'ยอดสินค้าคงคลัง',
  expiry_date: 'วันหมดอายุ',
  action: 'ดำเนินการ',
  create_product: 'สร้างสินค้า',
  upload_by_excel: 'อัพโหลดด้วย Excel',
  product_code: 'รหัสสินค้า',
  product_code_helper: 'รหัสสินค้าอ้างอิงภายใน',
  ean: 'หมายเลขบาร์โค้ด 13 หลัก (ถ้ามี)',
  ean_helper: '13 หลัก',
  local_name_english: 'ชื่อภาษาอังกฤษ (ถ้ามี)',
  local_name_helper: 'นาย ABC',
  category: 'หมวดสินค้า',
  sub_category: 'หมวดย่อยสินค้า',
  phone_number: 'เบอร์มือถือ',
  password: 'รหัสผ่าน',
  login_false: 'ลงชื่อเข้าใช้ไม่สำเร็จ',
  login_success: 'ลงชื่อเข้าใช้สำเร็จ',
  update_product_id: 'อัพเดทสินค้า: {id}',
  update_product: 'อัพเดทสินค้า',
  overview_title: 'ภาพรวม',
  hello_mes: 'แดชบอร์ด',
  good_morning: 'สวัสดี',
  filter_date: 'ตัวกรองวันที่',
  filter_button: 'ตัวกรอง',
  total_sell_quantity: 'รวมปริมาณสินค้าขาย',
  total_sales: 'รวมยอดเงินขาย',
  dashboard: 'แดชบอร์ด',
  product_name: 'ชื่อสินค้า',
  buyer_name: 'ชื่อผู้ซื้อ',
  total_price: 'รวมราคาทั้งสิ้น',
  total_quantity: 'จำนวนทั้งสิ้น',
  see_all: 'ดูทั้งหมด',
  product_create_success: 'สร้างสำเร็จ',
  product_update_success: 'อัพเดทสำเร็จ',
  product_update_error: 'อัพเดทไม่สำเร็จ',
  product_create_error: 'สร้างไม่สำเร็จ',
  max_image_upload: 'คุณสามารถอัพโหลดได้สูงสุด  {maxLength} ไฟล์',
  image_upload_error: 'อัพโหลดรูปภาพไม่สำเร็จ',
  select_file: 'เลือกไฟล์',
  sku: 'รหัสสินค้าภายใน',
  sku_helper:
    'เลขสินค้าภายในจะต้องไม่ซ้ำกัน ซึ่งใช้ในการอ้างอิงภายในของท่านเท่านั้น และ ไม่ได้ถูกสร้างขึ้นมาจากระบบ',
  product_local_name: 'ชื่อสินค้า',
  product_local_name_helper: 'คำอธิบายชื่อสินค้า',
  product_eng_name: 'ชื่อสินค้าภาษาอังกฤษ',
  product_common_name: 'ชื่อสามัญ',
  product_common_name_helper: 'คำอธิบายชื่อสามัญสินค้าภาษาอังกฤษ',
  product_eng_name_helper: 'คำอธิบายชื่อสินค้าภาษาอังกฤษ',
  product_type: 'ชนิด',
  brand_name: 'ชื่อเฉพาะของผู้ใช้',
  brand_name_helper: 'คำอธิบายชื่อแบรนด์',
  origin: 'แหล่งที่มา',
  origin_helper: 'ประเทศแหล่งที่มา',
  unit_type: 'ชนิดของหน่วย',
  general_des: 'คำอธิบายทั่วไป',
  general_des_helper: 'คำอธิบายสินค้า',
  tech_des: 'คำอธิบายด้านเทคนิค',
  tech_des_helper: 'คำอธิบายด้านเทคนิค',
  manual_guide: 'วิธีการใช้งาน',
  manual_guide_helper: 'วิธีการใช้งาน',
  inventory_num_helper: 'จำนวน',
  price_helper: 'ราคารวมภาษีมูลค่าเพิ่ม',
  curreny: 'สกุลเงิน',
  curreny_helper: 'สกุลเงินกำหนดอัตโนมัติ',
  note: 'บันทึก',
  note_helper: 'คำอธิบายบันทึก',
  on_hand_date: 'ปริมาณสินค้าวันที่',
  on_hand_date_helper: 'ปริมาณสินค้าวันที่',
  expiry_list_expridate: 'วันหมดอายุ',
  expridate_helper: 'คำอธิบายวันหมดอายุ',
  expiry_list_quantity: 'จำนวนสินค้าที่จะหมดอายุ',
  expiry_list_quantity_helper: 'คำอธิบายจำนวนสินค้าที่จะหมดอายุ',
  warranty_note: 'การรับประกันสินค้า',
  warranty_note_helper: 'คำอธิบายการรับประกันสินค้า',
  status_active: 'ขายอยู่',
  status_inactive: 'ไม่ขายแล้ว',
  vat: 'ภาษีมูลค่าเพิ่ม',
  yes: 'ใช่',
  no: 'ไม่ใช่',
  group_1_price: 'ราคากลุ่มที่ 1',
  group_1_price_helper: 'ส่วนลดราคากลุ่มที่ 1',
  group_1_quantity: 'จำนวนกลุ่มที่ 1',
  group_1_quantity_helper: 'จำนวนทั้งหมดกลุ่มที่ 1',
  group_2_price: 'ราคากลุ่มที่ 2',
  group_2_price_helper: 'ส่วนลดราคากลุ่มที่ 2',
  group_2_quantity: 'จำนวนกลุ่มที่ 2',
  group_2_quantity_helper: 'จำนวนทั้งหมดกลุ่มที่ 2',
  group_3_price: 'ราคากลุ่มที่ 3',
  group_3_price_helper: 'ส่วนลดราคากลุ่มที่ 3',
  group_3_quantity: 'จำนวนกลุ่มที่ 3',
  group_3_quantity_helper: 'จำนวนทั้งหมดกลุ่มที่ 3',
  agent_level_1: 'ระดับที่ 1',
  agent_level_1_helper: 'ส่วนลดของตัวแทนกลุ่มที่ 1',
  agent_level_2: 'ระดับที่ 2',
  agent_level_2_helper: 'ส่วนลดของตัวแทนกลุ่มที่ 2',
  agent_level_3: 'ระดับที่ 3',
  agent_level_3_helper: 'ส่วนลดของตัวแทนกลุ่มที่ 3',
  agent_level_4: 'ระดับที่ 4',
  agent_level_4_helper: 'ส่วนลดของตัวแทนกลุ่มที่ 4',
  agent_level_5: 'ระดับที่ 5',
  agent_level_5_helper: 'ส่วนลดของตัวแทนกลุ่มที่ 5',
  cancel: 'ยกเลิก',
  product_delete_confirm: 'ยืนยันการลบ',
  load_more: 'แสดงเพิ่มเติม',
  order_management: 'จัดการคำสั่งซื้อ',
  order: 'รายการ',
  inquiry_created: 'คำสั่งซื้อถูกสร้าง',
  wait_for_update_fee: 'รอการตอบกลับจากผู้ขาย',
  dms_reject: 'ผู้ขายปฏิเสธ',
  farm_shop_reject: 'ผู้ซื้อปฏิเสธ',
  farmshop_bank_confirm: 'ผู้ซื้อยืนยันชำระเงิน',
  wait_for_farmshop_confirm: 'รอผู้ซื้อยืนยัน',
  on_progress: 'อยู่ระหว่างดำเนินการ',
  confirm_order_success: 'ยืนยันว่าสำเร็จ',
  confirm_order_error: 'ยืนยันว่าไม่สำเร็จ',
  delivery_order_success: 'จัดส่งสินค้าสำเร็จ',
  delivery_order_error: 'จัดส่งสินค้าไม่สำเร็จ',
  confirm: 'ยืนยัน',
  delivery: 'จัดส่งสินค้า',
  order_by: 'สั่งโดย',
  more: 'เพิ่มเติม',
  farm_shop_management: 'จัดการตัวแทน',
  farm_shop: 'รายชื่อตัวแทน',
  create_farm_shop_account: 'สร้างบัญชีตัวแทน',
  create_farm_shop: 'สร้าง',
  farm_name: 'ชื่อตัวแทน',
  username: 'โทรศัพท์มือถือ',
  agent_level: 'ระดับ',
  total_order: 'รวมยอดขาย',
  last_order: 'วันที่สั่งสินค้าครั้งสุดท้าย',
  avatar_update_false: 'อัพเดทรูปแทนตัวตนไม่สำเร็จ',
  upload_image: 'อัพโหลด',
  user_update_success: 'อัพเดทผู้ใช้สำเร็จ',
  user_update_false: 'อัพเดทผู้ใช้ไม่สำเร็จ',
  user_local_name: 'ชื่อ',
  user_eng_name: 'ชื่อภาษาอังกฤษ',
  email: 'อีเมล์',
  city: 'จังหวัด',
  district: 'อำเภอ/เขต',
  ward: 'ตำบล/แขวง',
  post_code: 'รหัสไปรษณีย์',
  main_category: 'สินค้าหลัก',
  user_general_des: 'คำอธิบายทั่วไป',
  video: 'วีดีโอ',
  add_link_video: 'ลิ้งค์วีดีโอ',
  images: 'รูปภาพ',
  bank_account_information: 'ข้อมูลบัญชีธนาคาร',
  save_change: 'บันทึก',
  update_bank_information: 'อัพเดทข้อมูลธนาคาร',
  bank_name: 'ชื่อธนาคาร',
  account_number: 'เลขบัญชี',
  owner_name: 'ชื่อบัญชี',
  ok: 'โอเค',
  wholesaler_information: 'ข้อมูลผู้ขาย',
  settings: 'ตั้งค่า',
  general_setting: 'ข้อมูลผู้ใช้',
  change_password: 'เปลี่ยนรหัสผ่าน',
  password_update_success: 'อัพเดทรหัสผ่านสำเร็จ',
  password_update_false: 'อัพเดทรหัสผ่านไม่สำเร็จ',
  confirm_password: 'ยืนยันรหัสผ่าน',
  sign_out: 'ออกจากระบบ',
  sign_in: 'เข้าใช้งาน',
  is_required: 'จำเป็น',
  do_not_have_account: 'ไม่มีบัญชี?',
  have_account: 'มีบัญชี?',
  verify_code: 'ตรวจสอบรหัส',
  register_false: 'ลงทะเบียนไม่สำเร็จ',
  register_success: 'ลงทะเบียนสำเร็จ',
  code: 'รหัส OTP',
  create_account: 'สร้างบัญชี',
  '404_notice': '401: เราขอโทษที่ไม่สามารถระบุตัวตนของคุณ',
  '404_sub_notice':
    'คุณอาจจะเข้ามาผิดวิธีหรือเข้ามาด้วยความผิดพลาด กรุณาใช้เส้นทางปกติ',
  back_home: 'กลับสู่หน้าแรก',
  product_manufacturer_name: 'ชื่อผู้ผลิตสินค้า',
  product_manufacturer_name_helper: 'คำอธิบายชื่อผู้ผลิตสินค้า',
  product_importer_name: 'ชื่อผู้นำเข้าสินค้า',
  product_importer_name_helper: 'คำอธิบายชื่อนำเข้าสินค้า',
  product_distributor_name: 'ชื่อผู้กระจายสินค้า',
  product_distributor_name_helper: 'คำอธิบายชื่อผู้กระจายสินค้า',
  copy_to_clipboard: 'คัดลอกไปยังคลิปบอร์ด',
  field_required: 'ช่องนี้จำเป็นต้องใส่ข้อมูล',
  price_by_gps_management: 'ราคารายพื้นที่',
  price_by_gps: 'ราคาในพื้นที่',
  list_province: 'รายการจังหวัด',
  gps_price: 'ราคาในพื้นที่ที่เลือก',
  gps_price_delete_confirm: 'คุณแน่ใจที่จะลบใช่หรือไม่?',
  please_select: 'กรุณาเลือก 1 ตัวเลือก',
  image_upload_max_file_size_error: 'ขนาดไฟล์ต้องเล็กกว่า 5 MB',
  product_id: 'Product ID',
  price_helper_validate_price: 'ราคาต้องถูกกว่าราคาตั้ง',
  group_1_price_helper_validate_price: 'ราคาต้องถูกกว่าราคาปัจจุบัน',
  group_2_price_helper_validate_price: 'ราคาต้องถูกกว่าราคากลุ่มที่ 1',
  group_3_price_helper_validate_price: 'ราคาต้องถูกกว่าราคากลุ่มที่ 2',
  group_1_quantity_helper_validate_price:
    'จำนวนต้องมากกว่าจำนวนขั้นต่ำในการสั่งสินค้า',
  group_2_quantity_helper_validate_price: 'จำวนต้องมากกว่าจำนวนกลุ่มที่ 1',
  group_3_quantity_helper_validate_price: 'จำวนต้องมากกว่าจำนวนกลุ่มที่ 2',
  attach_registeration_file: 'แนบไฟล์ลงทะเบียน',
  attach_file: 'แนบไฟล์ลงทะเบียน',
  sku_existed: 'รหัสสินคาถูกใช้แล้ว กรุณาตั้งชื่อสินค้าด้วยรหัสอื่น',
  min_order_quantity: 'จำนวนขั้นต่ำในการสั่งสินค้า',
  min_order_quantity_helper: 'คำอธิบายจำนวนขั้นต่ำในการสั่งสินค้า',
  original_price_helper: 'ราคาตั้งต้องแพงกว่าราคาปัจจุบัน',
  credit_term_helper: 'เครดิตเทอมต้องเป็นเลขจำนวนเต็ม',
  credit_term: 'เครดิตเทอม',
  cost_price_helper: 'คำอธิบายต้นทุนของสินค้า',
  cost_price: 'ต้นทุนของสินค้า',
  product_private:
    'สินค้านี้ให้เฉพาะตัวแทนที่ท่านเลือกให้เห็นเท่านั้นใช่หรือไม่?',
  organic_product: 'สินค้านี้เป็นสินค้าอินทรีย์ใช่หรือไม่?',
  crop_or_animal: 'เลือกพืชหรือปศุสัตว์',
  type_of_crop: 'ชนิดของพืช',
  type_of_crop_helper: 'กรอกชนิดของพืช',
  growth_stage_of_crop: 'ระยะการเติบโตของพืช',
  growth_stage_of_animal: 'ระยะการเติบโตของปศุสัตว์',
  type_of_pest: 'ชนิดของศัตรูพืช',
  type_of_disease_helper: 'คำอธิบายชนิดของโรคพืช',
  type_of_disease: 'ชนิดของโรคพืช',
  type_of_weed_helper: 'คำอธิบายชนิดของวัชพืช',
  type_of_weed: 'ชนิดของวัชพืช',
  registration_year_helper: 'คำอธิบายปีที่ลงทะเบียน',
  registration_year: 'ปีที่ลงทะเบียน',
  registration_number_helper: 'คำอธิบายเลขทะเบียน',
  registration_number: 'เลขทะเบียน',
  most_recent_registration_renewal_date_helper:
    'คำอธิบายวันที่ต่ออายุทะเบียนล่าสุด',
  most_recent_registration_renewal_date: 'วันที่ต่ออายุทะเบียนล่าสุด',
  discount_percent: 'ส่วนลด %',
  max_discount_day: 'ชำระเงินภายในกี่วันถึงจะได้ส่วนลด (นับจากวันสั่งซื้อ)',
  max_pay_day: 'ชำระเงินได้ช้าที่สุดกี่วัน (นับจากวันสั่งซื้อ)',
  type_of_animal: 'ชนิดของปศุสัตว์',
  type_of_animal_disease: 'ชนิดของโรคปศุสัตว์',
  type_of_animal_disease_helper: ' ',
  seller_name_eng: 'ชื่อผู้ขาย (ภาษาอังกฤษ)',
  seller_name_local: 'ชื่อผู้ขาย (ภาษาท้องถิ่น)',
  contact_person_name_eng: 'ชื่อผู้ประสานงาน (ภาษาอังกฤษ)',
  contact_person_name_local: 'ชื่อผู้ประสานงาน (ภาษาท้องถิ่น)',
  contact_person_profile_picture: 'รูปโปรไฟล์ผู้ประสานงาน',
  seller_brand_logo: 'โลโก้แบรนด์ผู้ขาย',
  contact_person_position: 'ตำแหน่งผู้ประสานงาน',
  address: 'ที่อยู่',
  general_description: 'คำอธิบายทั่วไปของผู้ซื้อ',
  annual_revenue: 'รายได้ต่อปี',
  annual_revenue_helper: 'รายได้ต่อไปรวมทั้งออนไลน์และออฟไลน์',
  total_target_sales_on_amm_per_month:
    'กรุณาตั้งเป้ายอดขายเฉพาะที่เกิดขึ้นบนอกริโมโม (ต่อเดือน)',
  total_target_fertilizer: 'รวมเป้ายอดซื้อปุ๋ย',
  total_target_fertilizer_helper: 'คำอธิบายรวมเป้ายอดซื้อปุ๋ย',
  total_target_agro_chem: 'รวมเป้ายอดซื้อเคมีเกษตร',
  total_target_seed: 'รวมเป้ายอดซื้อเมล็ดพันธุ์',
  total_target_seed_helper: 'คำอธิบายรวมเป้ายอดซื้อเมล็ดพันธุ์',
  total_target_animal_feed: 'รวมเป้ายอดซื้อปศุสัตว์',
  total_target_animal_feed_helper: 'คำอธิบายรวมเป้ายอดซื้อปศุสัตว์',
  total_target_machinery_tools: 'เป้ายอดขายเครื่องจักรและอุปกรณ์',
  total_target_machinery_tools_helper:
    'คำอธิบายรวมเป้ายอดซื้อเครื่องมือและเครื่องจักรทางการเกษตร',
  credit_term_offered: 'เครดิตเทอม',
  pre_pay: 'ชำระเงินก่อนส่งสินค้า',
  cod: 'เก็บเงินปลายทาง',
  after_30_days: 'ชำระเงินภายใน 30 วันนับจากวันสั่งสินค้า',
  after_45_days: 'ชำระเงินภายใน 45 วันนับจากวันสั่งสินค้า',
  after_60_days: 'ชำระเงินภายใน 60 วันนับจากวันสั่งสินค้า',
  after_90_days: 'ชำระเงินภายใน 90 วันนับจากวันสั่งสินค้า',
  after_120_days: 'ชำระเงินภายใน 120 วันนับจากวันสั่งสินค้า',
  after_180_days: 'ชำระเงินภายใน 180 วันนับจากวันสั่งสินค้า',
  after_365_days: 'ชำระเงินภายใน 365 วันนับจากวันสั่งสินค้า',
  other_term_offer: 'เงื่อนไขอื่นๆ',
  other_term_offer_helper: 'คำอธิบายเงื่อนไขอื่นๆ',
  commision_model: 'รูปแบบการคิดค่าคอมมิชชั่น',
  all_transaction_commission: 'คิดค่าคอมมิชชั่นทุกธุรกรรม',
  only_new_customer_commission:
    'คิดค่าคอมมิชชั่นเฉพาะลูกค้าใหม่ (คำนวณเฉพาะลูกค้าใหม่ ยกเว้นยอดขายจากลูกค้าปัจจุบันที่มีการแจ้งชื่อและรายละเอียดตั้งแต่วันที่สมัครใช้งาน, มีผล 1 ปีนับจากวันสมัครใช้งานหลังจากนั้นใช้วิธีคิดค่าคอมมิชชั่นทุกรายการ)',
  delivered_by_seller_3PL_appointed:
    'ส่งสินค้าโดยผู้ขาย หรือ บริษัทขนส่งที่ผู้ขายกำหนด',
  total_no_of_customers: 'จำนวนลูกค้าปัจจุบันทั้งสิ้น',
  list_new_customers: 'เพิ่มลูกค้าปัจจุบัน',
  min_day_delivery: 'ส่งสินค้าเร็วที่สุดในกี่วัน',
  max_day_delivery: 'ส่งสินค้าช้าที่สุดในกี่วัน',
  type_of_seller: 'ประเภทของผู้ขาย',
  individual: 'บุคคลธรรมดา',
  juristic: 'นิติบุคคล',
  manufacturer_seller_activities: 'ผู้ผลิต',
  distributor_seller_activities: 'ผู้กระจายสินค้า',
  importer_seller_activities: 'ผู้นำเข้า',
  copy_of_id_card_pass_port:
    'สำเนาบัตรประชาชนหรือหนังสือเดินทางของผู้มีอำนาจ + เซ็นรับรองสำเนาโดยผู้มีอำนาจ',
  copy_of_bank_account_book: 'สำเนาบัญชีธนาคาร + เซ็นรับรองสำเนาโดยผู้มีอำนาจ',
  copy_of_source_document:
    'สำเนาเอกสารแสดงที่มาของสินค้า เช่น ใบสั่งซื้อสินค้า, สัญญาซื้อขายสินค้า, สัญญาแต่งตั้งตัวแทนจัดจำหน่าย, สัญญาผลิตสินค้า, ใบอนุญาตโรงงาน, ใบอนุญาตนำเข้า โดยหน่วยงานที่เกี่ยวข้อง (ถ้ามี)',
  copy_of_company_affidavit:
    'สำเนาหนังสือรับรอง (ไม่เกิน 3 เดือน) + เซ็นรับรองสำเนาโดยผู้มีอำนาจ',
  copy_of_value_added_tax:
    'สำเนาใบทะเบียนภาษีมูลค่าเพิ่ม (ภ.พ.20) + เซ็นรับรองสำเนาโดยผู้มีอำนาจ (ถ้ามี)',
  copy_of_power_of_attorney: 'ใบมอบอำนาจ (ถ้ามี)',
  hear_about_agrimomo: 'ท่านรู้จักอกริโมโมได้อย่างไร?',
  hear_on_web_app: 'เว็บไซต์/แอปของอกริโมโม',
  hear_on_social_media: 'สื่อสังคมออนไลน์',
  hear_on_word_of_mouth: 'การบอกเล่าแบบปากต่อปาก',
  hear_on_local_authorities:
    'หน่วยงานท้องถิ่น (เช่น กรมวิชาการเกษตร, สหกรณ์การเกษตร, เป็นต้น)',
  submitted_time: 'เวลาส่งข้อมูล',
  seller_time_submitted: 'เวลาส่งข้อมูลโดยผู้ขาย',
  amm_time_submitted: 'เวลาส่งข้อมูลโดยอกริโมโม',
  farmshop_name_eng: 'ชื่อร้านค้าของลูกค้า (ภาษาอังกฤษ)',
  farmshop_name_local: 'ชื่อร้านค้าของลูกค้า (ภาษาท้องถิ่น)',
  farmshop_owner_name_eng: 'ชื่อเจ้าของร้านค้า (ภาษาอังกฤษ)',
  farmshop_owner_name_local: 'ชื่อเจ้าของร้านค้า (ภาษาท้องถิ่น)',
  farmshop_type: 'ประเภทของร้านค้า',
  manufacturer: 'ผู้ผลิต',
  reseller: 'ร้านค้า',
  farmshop_mobile: 'เบอร์มือถือ',
  farmshop_email: 'อีเมล์',
  farmshop_house_number: 'บ้านเลขที่',
  farmshop_street_name: 'ถนน',
  farmshop_village_name: 'หมู่บ้าน',
  farmshop_sub_district_name: 'ตำบล/แขวง',
  farmshop_district_name: 'อำเภอ/เขต',
  farmshop_province: 'จังหวัด',
  farmshop_country: 'ประเทศ',
  farmshop_post_code: 'รหัสไปรษณีย์',
  farmshop_google_map: 'ลิ้งค์แผนที่กูเกิ้ล',
  farmshop_picture: 'รูปถ่ายหน้าร้านค้า',
  farmshop_description: 'คำอธิบายทั่วไปของร้านค้า',
  farmshop_feature_category: 'กลุ่มสินค้าที่ร้านค้าขายอยู่',
  farmshop_agent_level: 'ระดับกลุ่มตัวแทน',
  total_target_animal_machinary_tools:
    'รวมเป้ายอดซื้อเครื่องมือและเครื่องจักรทางการเกษตร',
  overview: 'ภาพรวม',
  delivery_time: 'ระยะเวลาส่งสินค้า',
  documents: 'เอกสาร',
  financial_section: 'ส่วนการเงิน',
  product_section: 'ส่วนสินค้า',
  continue_later: 'ทำต่อทีหลัง',
  submit: 'ส่งข้อมูล',
  trader_seller_activities: 'ผู้ค้าขาย',
  survey: 'สำรวจข้อมูล',
  province: 'จังหวัด',
  sub_district: 'ตำบล/แขวง',
  seller_activities: 'กิจกรรมของผู้ขาย',
  time_submitted: 'เวลาส่งข้อมูลโดยผู้ขาย',
  time_update: 'เวลาอัพเดทข้อมูลโดยผู้ขาย',
  amm_time_verified: 'เวลาตรวจสอบข้อมูลโดยอกริโมโม',
  amm_time_reject: 'เวลาปฏิเสธข้อมูลโดยอกริโมโม',
  other_term_offered: 'เงื่อนไขอื่นๆ',
  other_term_offered_helper: 'คำอธิบายเงื่อนไขอื่นๆ',
  discount_percent_helper: ' ',
  max_discount_day_helper: ' ',
  max_pay_day_helper: ' ',
  seller_name_eng_helper: ' ',
  general_seller_description_helper: ' ',
  seller_name_local_helper: ' ',
  contact_person_name_eng_helper: ' ',
  contact_person_name_local_helper: ' ',
  contact_person_position_helper: ' ',
  address_helper: ' ',
  email_helper: ' ',
  phone_number_helper: 'รูปแบบ: +66813361789',
  total_target_agro_chem_helper: ' ',
  total_target_animal_machinary_tools_helper: ' ',
  farmshop_name_eng_helper: ' ',
  farmshop_name_local_helper: ' ',
  farmshop_owner_name_eng_helper: ' ',
  farmshop_owner_name_local_helper: ' ',
  farmshop_mobile_helper: ' ',
  farmshop_email_helper: ' ',
  farmshop_house_number_helper: ' ',
  farmshop_street_name_helper: ' ',
  farmshop_village_name_helper: ' ',
  farmshop_sub_district_name_helper: ' ',
  farmshop_district_name_helper: ' ',
  farmshop_province_helper: ' ',
  farmshop_country_helper: ' ',
  farmshop_post_code_helper: ' ',
  farmshop_google_map_helper: ' ',
  farmshop_description_helper: ' ',
  min_day_delivery_helper: ' ',
  max_day_delivery_helper: ' ',
  type_of_animal_helper: ' ',
  growth_stage_of_crop_helper: ' ',
  growth_stage_of_animal_helper: ' ',
  date: 'วันที่ทำธุรกรรม',
  compliance_section: 'ส่วนการปฏิบัติตามกฏหมาย',
  prod_img: 'อัพโหลดรูปภาพสินค้าในสภาพขายปลีก',
  wholesale_img: 'อัพโหลดรูปภาพสินค้าในสภาพขายส่ง',
  max_file_size_upload_title: 'มากที่สุด 5 ไฟล์ แต่ละไฟล์ไม่เกิน 5 MB',
  sub_product_type: 'ชนิดของกลุ่มสินค้าย่อย',
  crop: 'พืช',
  animal: 'ปศุสัตว์',
  price_exclude_vat_helper: 'ราคาไม่รวมภาษีมูลค่าเพิ่ม',
  expiry_list_batch_number: 'หมายเลขการผลิต',
  expiry_list_batch_number_helper: 'คำอธิบายหมายเลขการผลิต',
  unit: 'หน่วย',
  seller_kyc: 'ตรวจสอบผู้ซื้อ',
  kyc: 'ตรวจสอบผู้ขาย',
  last_updated_date: 'อัพเดทล่าสุด',
  manufacture: 'ผู้ผลิต',
  sign_up: 'ลงทะเบียน',
  i_have_accept: 'ฉันได้ยอมรับ',
  privacy_policy: 'นโยบายความเป็นส่วนตัว',
  seller_agreement: 'สัญญาให้บริการตลาดกลาง',
  user_existed: 'บัญชีผู้ใช้ซ้ำ',
  sign_in_des: 'ลงชื่อเข้าใช้',
  soft_delete: 'บัญชีถูกระงับ กรุณาติดต่อแอดมินของอกริโมโม',
  wait_5_mins: 'กรุณารอ 5 นาทีเพื่อรับ SMS อีกครั้ง',
  wrong_username_format: 'กรุณากรอกหมายเลขโทรศัพท์ที่ถูกต้อง',
  wrong_code: 'รหัส OTP ผิด',
  create_price_by_gps: 'สร้างราคารายพื้นที่',
  create: 'สร้าง',
  created: 'สร้างแล้ว',
  already_existed: 'มีอยู่แล้ว',
  price_gps_deleted: 'ลบแล้ว',
  location_updated: 'อัปเดตตำแหน่งสำเร็จ',
  True: 'ใช่',
  False: 'ไม่ใช่',
  agent_existed: 'มีอยู่แล้ว',
  create_account_success: 'สร้างสำเร็จ!',
  relation_updated: 'อัพเดทแล้ว',
  no_properties: 'ไม่มีสรรพคุณทางยา',
  check_blank: 'กรุณาใส่ข้อมูลในกล่องสีแดง',
  check_price: 'กรุณาตรวจสอบราคาในกล่องสีแดง',
  logistic_fee_number: 'ค่าขนส่ง',
  logistic_fee_image: 'ใบเสนอราคา',
  user_order_name: 'ชื่อผู้ซื้อ',
  prod_updated: 'อัพเดทแล้ว',
  prod_created: 'สร้างแล้ว',
  and: 'และ',
  me: 'ฉัน',
  list_province_required: 'ต้องระบุรายการจังหวัด',
  admin: 'แอดมิน',
  change_pass_des: 'รหัสผ่านใหม่คือรหัส OTP',
  verify_number: 'รับรหัสผ่านใหม่',
  forgot_password: 'ลืมรหัสผ่าน?',
  prod_deleted: 'ลบแล้ว',
  change_pass_error: 'รหัสอินพุต',
  change_pass_success: 'ประสบความสำเร็จ',
  animal_food: 'ปศุสัตว์',
  max_file_size_upload: 'คุณสามารถอัพโหลดไฟล์ได้สูงสุด {maxFileSize}MB',
  brand_name_1: 'ชื่อแบรนด์',
  branch_name: 'ชื่อสาขา',
  invoice: 'ใบแจ้งหนี้',
  update_invoice: 'อัพเดทใบแจ้งหนี้',
  begin_delivery: 'เริ่มจัดส่งสินค้า',
  update_invoice_success: 'อัพเดทใบแจ้งหนี้สำเร็จ',
  update_invoice_error: 'อัพเดทใบแจ้งหนี้ไม่สำเร็จ',
  dialog_confirm_delivery:
    'กรุณายืนยันว่าท่านได้รับชำระเงินเต็มจำนวนก่อนทำการจัดส่งสินค้า',
  confirm_delivery_success: 'ยืนยันจัดส่งสินค้าสำเร็จ',
  confirm_delivery_error: 'ยืนยันจัดส่งสินค้าไม่สำเร็จ',
  delivery_terms: 'เงื่อนไขการจัดส่งสินค้า',
  delivery_terms_desc:
    'คำสั่งซื้อนี้จะได้รับการจัดส่งถึงผู้ซื้อภายใน 2 ถึง 5 วัน',
  payment_terms: 'เงื่อนไขการชำระเงิน',
  payment_terms_1: 'คำสั่งซื้อนี้ต้องชำระเงินก่อนส่งสินค้า',
  payment_terms_2: 'คำสั่งซื้อนี้ต้องชำระเงินโดยวิธีเก็บเงินปลายทาง',
  payment_terms_3: 'คำสั่งซื้อนี้ต้องชำระเงินภายหลังตามเครดิตเทอม',
  payment_terms2_note:
    'กรุณาตรวจสอบว่าผู้ให้บริการขนส่งมีบริการเก็บเงินปลายทาง',
  reject_order_note: 'คุณแน่ใจหรือไม่ ว่าต้องการที่จะยกเลิกคำสั่งซื้อนี้?',
  rule:
    'ฉันรับทราบว่าอกริโมโมจะไม่รับผิดชอบสำหรับความเสียหายที่เป็นผลสืบเนื่อง ความเสียหายพิเศษ ความเสียหายที่เป็นการลงโทษหรือความเสียหายที่เกิดขึ้นจากหรือเกี่ยวข้องกับธุรกรรมนี้ รวมถึงการสูญเสียผลกำไรหรือการสูญเสียธุรกิจไม่ว่าในกรณีใดๆ',
  pre_paid: 'คำสั่งซื้อนี้ต้องชำระเงินก่อนส่งสินค้า',
  wait_for_confirm: 'รอผู้ซื้อยืนยัน',
  cash_on_delivery: 'เงินสดในการจัดส่ง',
  note_cash_on_delivery:
    'กรุณาตรวจสอบว่าผู้ให้บริการขนส่งมีบริการเก็บเงินปลายทาง',
  credit_term_title: 'คำสั่งซื้อนี้จะต้องชำระเงินตามเครดิตเทอมดังต่อไปนี้',
  note_credit_term:
    'ผู้ซื้อจะได้รับส่วนลด {credit_term_discount}% หากชำระเงินภายใน {credit_term_period} วันนับจากวันสั่งสินค้า หาไม่แล้วจะต้องชำระเงินภายใน {payment_due_in_days} วันนับจากวันสั่งสินค้า',
  check_image: 'กรุณาอัพโหลดรูปภาพสินค้าในสภาพขายปลีกและสภาพขายส่งให้ครบถ้วน',
  delete: 'ลบ',
  form_product_cancel_confirm:
    'ท่านจะสูญเสียข้อมูลที่ไม่ได้บันทึก ท่านแน่ใจใช่หรือไม่?',
  check_file: 'กรุณาอัพโหลดไฟล์ให้ครบทุกไฟล์',
  term_of_use: 'เงื่อนไขและข้อตกลง และ นโยบายความเป็นส่วนตัว',
  term_and_condition: 'เงื่อนไขและข้อตกลง',
  distribution_management: 'ระบบบริหารการกระจายสินค้า',
  order_price: 'ราคาที่ซื้อมา',
  order_id: 'รหัสคำสั่งซื้อ',
  bank_transfer_image: 'รูปภาพสลิปโอนเงินธนาคาร',
  update_logistic_fee: 'อัพเดทใบเสนอราคา',
  price_by_gps_des:
    'หมายเหตุ: ราคารายพื้นที่จะแสดงแทนราคาปัจจุบันสำหรับพื้นที่ที่กำหนด ซึ่งราคาส่วนลดของตัวแทนที่ตั้งค่าในส่วนการจัดการสินค้ายังคงมีผลตามปกติ',
  Profile: 'โปรไฟล์',
  buyer_must_confirm_the_order:
    'ผู้ซื้อต้องตอบรับเงื่อนไขภายใน 2 วันนับจากวันที่ได้รับเงื่อนไขนี้',
  why_close_the_sales: 'ทำไมต้องจบการซื้อขายบนอกริโมโม?',
  authentic_products: 'สินค้าของแท้ 100%',
  verified_sellers: 'ตรวจสอบตัวตนผู้ขายด้วยระบบ Seller KYC',
  buyer_protection: 'ผู้ซื้อได้รับความคุ้มครองกรณีคืนเงิน/คืนสินค้า',
  price_excluding_logistic_fee: 'ราคาไม่รวมค่าขนส่ง',
  watch_how_to_vdo: 'ชมวิดีโอ"สมัครเป็นผู้ขาย"',
  street_address: 'ที่อยู่',
  mode_of_action_helper: ' ',
  add: 'เพิ่ม',
  new_option: 'ตัวเลือกใหม่',
  mode_of_action: 'กลไกการออกฤทธิ์',
  contact_person_first_name_english: 'ชื่อผู้ประสานงาน (ภาษาอังกฤษ)',
  contact_person_last_name_english: 'นามสกุลผู้ประสานงาน (ภาษาอังกฤษ)',
  contact_person_first_name_local: 'ชื่อผู้ประสานงาน (ภาษาไทย)',
  contact_person_last_name_local: 'นามสกุลผู้ประสานงาน (ภาษาไทย)',
  birth_date: 'วันเกิด',
  birth_date_validate: 'จำเป็นต้องมีอายุมากกว่า 20 ปีและอายุน้อยกว่า 120 ปี',
  identity_id: 'รหัสประจำตัว',
  identity_id_validate: 'กรุณากรอกตัวเลขให้ครบ 13 หลัก',
  phone_number_validate: 'กรุณากรอกเบอร์โทรศัพท์ในรูปแบบ "+66123456789"',
  nationality: 'สัญชาติ',
  postcode: 'รหัสไปรษณีย์',
  address_no: 'ที่อยู่',
  occupation_code: 'ประเภทธุรกิจ',
  profession_code: 'ประเภทบุคคล',
  submit_step1: 'ขั้นตอนที่ 1: กดส่งข้อมูล',
  submit_step2:
    'ขั้นตอนที่ 2: คลิกปุ่ม "ยืนยันการตรวจสอบผู้ขาย" เพื่อยืนยันการชำระเงินของผู้ขาย',
  error_response: 'มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้งในภายหลัง',
  tax_id: 'เลขประจำตัวผู้เสียภาษี',
  discount_description:
    'ส่วนลดพิเศษไม่ควรเกิน 90% ของ "ราคาไม่รวมค่าขนส่ง" ซึ่งเป็นส่วนลดท้ายบิลที่ผู้ขายเสนอเอง จากการต่อรองกับผู้ซื้อผ่านระบบแชทของอกริโมโม ซึ่งไม่มีผลต่อการลดค่าธรรมเนียมตลาดกลางที่คิดจาก "ราคาไม่รวมค่าขนส่ง" ด้านบนไปแล้ว',
  start_time: 'เวลาเริ่มต้น',
  end_time: 'เวลาสิ้นสุด',
  payment_status: 'สถานะการชำระเงิน',
  order_date: 'วันที่สั่งสินค้า',
  payment_date: 'วันที่ชำระเงิน',
  active: 'ใช้งานอยู่',
  completed: 'เสร็จสมบูรณ์',
  rejected: 'ถูกปฏิเสธ',
  search: 'ค้นหา',
  reject_order: 'ปฏิเสธคำสั่งซื้อ',
  tax_invoice_receipt:
    'ใบกำกับภาษี/ใบเสร็จรับเงิน จากอกริโมโม (สําหรับผู้ขายเท่านั้น)',
  invoice_from_agrimomo: 'ใบแจ้งหนี้ จากอกริโมโม (สําหรับผู้ขายเท่านั้น)',
  view: 'ดู',
  cod_description_1:
    'หมายเหตุ ในกรณีชำระเงินปลายทาง ผู้ขายจะต้องทำการโอนชำระค่าธรรมเนียมให้กับอกริโมโมโดยตรง ผ่านทาง ธนาคารกสิกรไทย ชื่อบัญชี บริษัท อกริโมโม (ไทยแลนด์) จํากัด สาขา ถนนรัชดาภิเษก ห้วยขวาง เลขบัญชี 1532677838',
  cod_description_2:
    'เมื่อทางบริษัทได้รับเงินแล้ว จะดำเนินการออกใบกำกับภาษี/ใบเสร็จรับเงินให้แก่ผู้ขายทางอีเมล์',
  order_has_been_delivered: 'คำสั่งซื้อนี้ได้รับการจัดส่งแล้ว',
  buyer_confirm_within: 'กรุณาตอบรับเงื่อนไขภายใน 2 วัน',
  price_including_logistic_fee: 'ราคารวมค่าขนส่ง',
  net_price: 'ราคาสุทธิ',
  extra_discount: 'ส่วนลดพิเศษ',
  credit_term_note:
    '{credit_term_discount}% ส่วนลด ถ้าชำระภายใน {credit_term_period} (วัน), ยอดที่ถึงกำหนดชำระใน {payment_due_in_days} (วัน)',
  net: 'สูงสุด',
  logistic_fee_update_success: 'การอัปเดตค่าธรรมเนียมลอจิสติกส์สำเร็จ',
  logistic_fee_update_error: 'เกิดข้อผิดพลาดในการอัปเดตค่าธรรมเนียมลอจิสติกส์',
  update_payment_terms_success: 'อัปเดตเงื่อนไขการชำระเงินสำเร็จ',
  update_payment_terms_error: 'อัปเดตข้อผิดพลาดเกี่ยวกับเงื่อนไขการชำระเงิน',
  verify: 'ตรวจสอบ',
  get_otp: 'รับ otp',
  address_kyc: 'ถนน/หมู่บ้าน/อาคาร',
  kyc_save_success: 'KYC บันทึกความสำเร็จ',
  kyc_save_error: 'ข้อผิดพลาดในการบันทึก KYC',
  kyc_submit_success: 'KYC ส่งสำเร็จ',
  kyc_submit_error: 'ข้อผิดพลาดในการส่ง KYC',
  farm_shop_confirm_order_payments: 'ผู้ซื้อยืนยันการชำระเงิน',
  order_placed: 'สั่งซื้อแล้ว',
  seller_confirmed: 'ผู้ขายยืนยัน',
  buyer_confirmed: 'ผู้ซื้อยืนยัน',
  delivered: 'ส่งมอบแล้ว',
  message: 'ข้อความ',
  update_begin_delivery: 'อัพเดทและเริ่มจัดส่งสินค้า',
  buyer_has_agreed_terms: 'ผู้ซื้อได้ตกลงตามเงื่อนไขการให้สินเชื่อ',
  order_paid: 'คำสั่งซื้อชำระเงินแล้ว',
  order_received: 'รับออเดอร์แล้ว',
  order_received_paid: 'ได้รับคำสั่งซื้อและชำระเงินแล้ว',
  buyer_agreed_cod: 'ผู้ซื้อตกลงที่จะชำระเงินด้วยเงินสดในการจัดส่ง',
  order_delivered: 'กำลังจัดส่งคำสั่งซื้อนี้',
  receipt: 'ใบเสร็จ',
  buyer_paid_cod: 'ผู้ซื้อชำระเงินด้วยเงินสดในการจัดส่ง',
  'discount_%': 'ส่วนลด (%)',
  if_paid_within_days: 'ถ้าชำระภายใน (วัน)',
  due_in_days: 'ยอดที่ถึงกำหนดชำระใน (วัน)',
  due_date: 'กำหนดชำระเงิน',
  credit_terms: 'เครดิตเทอม',
  farm_shop_confirm_being_delivered: 'ผู้ซื้อยืนยันได้รับสินค้า',
  update_receipt: 'อัพเดทใบเสร็จรับเงิน',
  to_be: 'เป็น',
  to_be_cod: '   ',
  paid_later: 'จ่ายในภายหลัง',
  with_credit_terms: 'โดยมีเงื่อนไขเครดิต',
  with: 'กับ',
  with_cod: 'ด้วยวิธีการ',
  your_offer_is: 'ข้อเสนอของคุณคือ',
  order_rejected: 'รายการนี้ถูกปฏิเสธ',
  with_the_following_reason: 'เหตุผล',
  warning: 'คำเตือน',
  this_buyer_pay_later: 'ยืนยันให้ผู้ซื้อชำระเงินภายหลัง',
  paid: 'ชำระเงินแล้ว',
  dismiss: 'ยกเลิก',
  reason: 'เหตุผล',
  less_than: 'น้อยกว่า',
  more_than: 'มากกว่า',
  dms_cofirm_delivery: 'ผู้ขายยืนยันจัดส่งสินค้า',
  verify_otp: 'ยืนยันการตรวจสอบผู้ขาย',
  bank_remark: 'หมายเหตุ: เลขบัญชีธนาคารนี้ไม่สามารถเปลี่ยนได้',
  net_price_remark: 'หมายเหตุ: ราคาสุทธิต้องมากกว่า 20 บาทขั้นไป',
  paid_cod: 'ชำระเงิน',
  are_you_sure_you_want_to_confirm: 'ยืนยันการทำรายการ?',
  general_seller_description: 'คำอธิบายทั่วไปของผู้ขาย',
  feature_category: 'หมวดสินค้าแนะนำ',
  vendors: 'ร้านแนะนำ',
  popular_products: 'สินค้ายอดนิยม',
  by: 'โดย',
  featured_vendors: 'ผู้ขายแนะนำ',
  description: 'คำอธิบาย',
  visit_store: 'เยี่ยมชมร้านค้า',
  we_found: 'เราพบ',
  item_for_you: 'ชิ้นสำหรับคุณ!',
  add_to_cart: 'ใส่รถเข็น',
  availability: 'สินค้าพร้อมส่ง',
  items_in_stock: 'จำนวนสินค้าพร้อมส่ง',
  additional_info: 'ข้อมูลเพิ่มเติม',
  vendor: 'ร้านแนะนำ',
  related_products: 'สินค้าที่เกี่ยวข้อง',
  no_products_found: 'ไม่พบสินค้า',
  contact_seller: 'ติดต่อผู้ขาย',
  your_cart: 'รถเข็นของคุณ',
  there_are: 'มีอยู่',
  products_in_your_cart: 'สินค้าในรถเข็นของคุณ',
  clear_cart: 'ยกเลิกสินค้าในรถเข็น',
  unit_price: 'ราคาต่อหน่วย',
  quantity: 'จำนวน',
  subtotal: 'รวม',
  remove: 'ลบ',
  continue_shopping: 'ซื้อสินค้าเพิ่มเติม',
  cart_totals: 'รวมมูลค่าสินค้าในรถเข็นทั้งสิ้น',
  cart_subtotal: 'รวมมูลค่าสินค้าในรถเข็น',
  shipping: 'ส่งสินค้า',
  free_shipping: 'ส่งฟรี',
  total: 'รวมทั้งสิ้น',
  proceed_to_payment: 'ดำเนินการชำระเงิน',
  checkout: 'ชำระเงิน',
  current_user: 'ผู้ใช้งานปัจจุบัน',
  billing_details: 'รายละเอียดของบิล',
  your_order: 'คำสั่งซื้อของคุณ',
  no_product: 'ไม่มีสินค้า',
  create_order: 'สร้างคำสั่งซื้อ',
  account: 'บัญชี',
  cart: 'รถเข็น',
  my_account: 'บัญชีของฉัน',
  orders: 'คำสั่งซื้อ',
  pos: 'ระบบขายหน้าร้าน',
  account_details: 'รายละเอียดบัญชี',
  total_buy: 'รวมยอดเงินซื้อ',
  revenue: 'รายได้',
  total_buy_quantity: 'รวมปริมาณสินค้าซื้อ',
  in_stock: 'สินค้าคงคลัง',
  my_orders: 'คำสั่งซื้อของฉัน',
  received: 'ฉันได้รับสินค้าแล้ว',
  no_order: 'ไม่มีคำสั่งซื้อ',
  display_name: 'ชื่อที่แสดง',
  email_address: 'อีเมล์',
  new_password: 'รหัสผ่านใหม่',
  product_detail: 'รายละเอียดสินค้า',
  selling_price: 'ราคาขาย',
  sold_quantity: 'จำนวนขาย',
  expridate: 'หมดอายุวันที่',
  update: 'อัพเดท',
  buyer_name_eng: 'ชื่อผู้ซื้อ (ภาษาอังกฤษ)',
  buyer_name_local: 'ชื่อผู้ซื้อ (ภาษาท้องถิ่น)',
  buyer_brand_logo: 'โลโก้แบรนด์ผู้ซื้อ',
  total_target_sales_on_amm: 'รวมเป้ายอดซื้อบนอกริโมโม (ต่อเดือน)',
  copy_of_authorized_trading:
    'สำเนาเอกสารเชิงพาณิชย์ เช่น ใบอนุญาตขายปุ๋ย, ใบอนุญาตมีไว้ครอบครองซึ่งวัตถุอันตราย, ใบอนุญาตขายเมล็ดพันธุ์ควบคุม, ใบอนุญาตขายอาหารสัตว์ควบคุมเฉพาะ, สัญญาซื้อขายสินค้า, สัญญาแต่งตั้งตัวแทนจัดจำหน่าย (ถ้ามี)',
  type_of_buyer: 'ประเภทของผู้ซื้อ',
  total_target_sales: 'รวมเป้ายอดซื้อ',
  retailer_seller_activities: 'ผู้ค้าปลีก',
  farmer_seller_activities: 'เกษตรกร',
  wholesaler_seller_activities: 'ผู้ค้าส่ง',
  buyer_activities: 'กิจกรรมของผู้ซื้อ',
  land_area: 'พื้นที่เพาะปลูก',
  buyer_name_eng_helper: ' ',
  buyer_name_local_helper: ' ',
  general_description_helper: ' ',
  eng_name: 'ชื่อภาษาอังกฤษ',
  manufacturer_name: 'ชื่อผู้ผลิตสินค้า',
  importer_name: 'ชื่อผู้นำเข้าสินค้า',
  distributor_name: 'ชื่อผู้กระจายสินค้า',
  discount_info: 'ข้อมูลส่วนลด',
  common_name: 'ชื่อสามัญ',
  report: 'รายงาน',
  brand_name_kyc: 'ชื่อเฉพาะของผู้ใช้',
  kyc_seller: 'ตรวจสอบผู้ขาย',
  seller_management: 'จัดการผู้ขาย',
  buyer_management: 'จัดการผู้ซื้อ',
  kyc_seller_management: 'จัดการตรวจสอบผู้ขาย',
  kyc_buyer_management: 'จัดการตรวจสอบผู้ซื้อ',
  seller_user: 'ผู้ใช้งานฝั่งผู้ขาย',
  buyer_user: 'ผู้ใช้งานฝั่งผู้ซื้อ',
  product_restore_confirm: 'ยืนยันกู้คืน?',
  delete_user_confirm: 'ยืนยันลบ?',
  restore_user_confirm: 'ยืนยันกู้คืน?',
  delete_buyer_confirm: 'ยืนยันลบ?',
  restore_buyer_confirm: 'ยืนยันกู้คืน?',
  seller_user_management: 'จัดการผู้ใช้งานฝั่งผู้ขาย',
  buyer_user_management: 'จัดการผู้ใช้งานฝั่งผู้ซื้อ',
  kyc_buyer: 'ตรวจสอบผู้ซื้อ',
  buyer_time_submitted: 'เวลาที่ผู้ซื้อส่งข้อมูล',
  buyer_time_update: 'เวลาที่ผู้ซื้ออัพเดทข้อมูล',
  user_id: 'รหัสผู้ใช้',
  update_time: 'เวลาอัปเดต',
  created_time: 'เวลาที่สร้าง',
  log: 'log',
  type: 'ชนิด',
  SellerManagement: 'จัดการผู้ขาย',
  BuyerManagement: 'จัดการผู้ซื้อ',
  SellerKYCManagement: 'จัดการตรวจสอบผู้ขาย',
  BuyerKYCManagement: 'จัดการตรวจสอบผู้ซื้อ',
  mkt_fee_options: 'ตัวเลือกค่าบริการตลาดกลาง',
  send_all_chat: 'ส่งข้อความให้ทุกคน',
  SellerUser: 'ผู้ขาย',
  user_name: 'ชื่อผู้ใช้งาน',
  buyer_setting: 'ตั้งค่าผู้ซื้อ',
  buyer_information: 'ข้อมูลผู้ซื้อ',
  buyer_kyc: 'ตรวจสอบผู้ซื้อ',
  seller_name: 'ชื่อผู้ขาย',
  proceed_to_order: 'ดำเนินการสั่งสินค้า',
  order_placement: 'สั่งสินค้า',
  shipping_details: 'รายละเอียดการจัดส่ง',
  place_order: 'ส่งคำสั่งซื้อ',
  login: 'เข้าใช้งาน',
  create_order_validate_blank_false: 'กรุณาใส่ข้อมูลให้ครบทุกส่วน',
  Vendors_List: 'รายชื่อผู้ขาย',
  We_have: 'เรามี',
  product_now: 'สินค้าในขณะนี้',
  vendors_now: 'ผู้ขายในขณะนี้',
  search_vendor_by_id: 'ค้นหาผู้ขาย (โดยชื่อ หรือ หมายเลข)…',
  amount: 'จำนวน',
  reject: 'ยกเลิก',
  show_banking_info: 'แสดงข้อมูลบัญชีธนาคาร',
  pay: 'ชำระเงิน',
  begin_delivery_soon: 'จะส่งสินค้าต่อไป',
  dialog_reject: 'คุณแน่ใจหรือไม่ ว่าต้องการที่จะปฏิเสธคำสั่งซื้อนี้?',
  dialog_cancel: 'คุณแน่ใจหรือไม่ ว่าต้องการที่จะยกเลิกคำสั่งซื้อนี้?',
  confirm_info: 'ที่อยู่จัดส่งสินค้าของฉันเหมือนกับที่อยู่ในบิลของฉัน',
  update_buyer_kyc_sucessfully: 'อัพเดทกระบวนตรวจสอบผู้ซื้อสำเร็จ',
  buyer_kyc_1: 'ตรวจสอบผู้ซื้อ',
  mkt_fee_options_1: 'จัดการค่าบริการตลาดกลางภาพรวม',
  fee_management: 'จัดการค่าบริการตลาดกลางภาพรวม',
  rate: 'อัตรา',
  start_date: 'เริ่มวันที่',
  end_date: 'สิ้นสุดวันที่',
  added_by: 'เพิ่มโดย',
  verified: 'ตรวจสอบแล้ว',
  seller_information: 'ข้อมูลผู้ขาย',
  login_here: 'ลงชื่อเข้าใช้',
  log_in: 'ลงชื่อเข้าใช้',
  phoneNumber: 'เบอร์มือถือ',
  your_password: 'รหัสผ่าน',
  create_account_here: 'ลงทะเบียน',
  policy_required:
    'ท่านต้องยอมรับ เงื่อนไขและข้อตกลง และ นโยบายความเป็นส่วนตัว',
  register: 'ลงทะเบียน',
  when_buy: 'เมื่อซื้อ',
  brand_name_display: 'ชื่อแบรนด์',
  become_a_seller: 'สมัครเป็นผู้ขาย',
  delivery_term: 'เงื่อนไขการจัดส่งสินค้า',
  order_delivered_by_seller:
    'คำสั่งซื้อนี้จะได้รับการจัดส่งถึงผู้ซื้อภายใน {startDate} ถึง {endDate} วัน',
  order_details: 'รายละเอียดคำสั่งซื้อ',
  benefits_for_buyers: 'ประโยชน์สำหรับผู้ซื้อ',
  buy_your_inventory: 'ซื้อสินค้าในราคาที่ดีที่สุด อย่างง่ายดาย',
  spend_less_time: 'การสั่งซื้อสินค้าใช้เวลาน้อยลง',
  instead_of_calling_multiple_wholesalers:
    'แทนที่จะโทรหาผู้ค้าส่งหลายรายเพื่อต่อรองราคา คุณสามารถซื้อสินค้าทั้งหมดของคุณทางออนไลน์ได้อย่างรวดเร็วและง่ายดาย',
  fully_automated_monitoring:
    'เราตรวจสอบผู้ขายอย่างเข้มงวด เพื่อให้ผู้ซื้อมั่นใจว่าสินค้าได้รับการรับรองมาตรฐานสินค้าที่เกี่ยวข้อง',
  flexible_payment_term: 'เงื่อนไขการชำระเงินที่ยืดหยุ่น',
  shop_more_freely:
    'ช้อปสินค้าได้อย่างอิสระมากขึ้นและทำให้กระแสเงินสดของคุณดีขึ้น',
  benefits_for_sellers: 'ประโยชน์สำหรับผู้ขาย',
  access_new_customers:
    'เข้าถึงลูกค้าใหม่และทำให้การกระจายสินค้าเป็นเรื่องง่าย',
  nationwide_distribution: 'จำหน่ายทั่วประเทศ',
  connect_with_a_network:
    'เชื่อมโยงกับเครือข่ายตัวแทนท้องถิ่น ร้านค้าเกษตรชุมชน และฟาร์มเชิงพาณิชย์ทั่วประเทศ',
  improve_operational_efficiency: 'ปรับปรุงประสิทธิภาพการดำเนินงาน',
  better_understand_your_order_flow:
    'เข้าใจขั้นตอนการสั่งซื้อ ลดความซับซ้อนของการดำเนินงาน และปรับปรุงการบริหารสินค้าคงคลัง',
  flexible_collection_term: 'เงื่อนไขการจัดเก็บเงินที่ยืดหยุ่น',
  reach_new_customers:
    'เข้าถึงลูกค้ารายใหม่ ลดหนี้เสีย และปรับตัวให้เข้ากับความคาดหวังของลูกค้าที่เปลี่ยนแปลงอย่างรวดเร็ว',
  Categories: 'หมวดสินค้า',
  Popularproduct: 'สินค้ายอดนิยม',
  Home: 'หน้าแรก',
  We_have_1: 'เรามี',
  Pages: 'หน้า',
  process_to_order: 'ดำเนินการสั่งสินค้า',
  Shop: 'ซื้อสินค้า',
  Cart: 'รถเข็น',
  Upload_Avatar: 'อัพโหลดรูปแทนตัวตน',
  BuyerKYC: 'ตรวจสอบผู้ซื้อ',
  About: 'เกี่ยวกับ',
  VisitStore: 'เยี่ยมชมร้านค้า',
  Fertilizer: 'ปุ๋ย',
  Chemical: 'เคมีเกษตร',
  Seed: 'เมล็ดพันธุ์',
  Animal_food: 'ปศุสัตว์',
  Tool: 'อุปกรณ์เกษตร',
  TotalPrice: 'รวมราคาทั้งสิ้น',
  shipping_details_1: 'รายละเอียดการจัดส่ง',
  place_order_1: 'ส่งคำสั่งซื้อ',
  Account: 'บัญชี',
  Vendors_List_1: 'รายชื่อผู้ขาย',
  We_have_2: 'เรามี',
  product_now_1: 'สินค้าตอนนี้',
  vendors_now_1: 'ผู้ขายตอนนี้',
  are_you_paying_cash_delivery:
    'คุณแน่ใจที่จะชำระเงินด้วยวิธีการเก็บเงินปลายทางใช่หรือไม่?',
  reject_farm_shop: 'ผู้ซื้อยกเลิก',
  Agrochemical: 'เคมีเกษตร',
  Machinerytools: 'อุปกรณ์เกษตร',
  Store: 'ร้านค้า',
  Selectconversationtodisplay: 'เลือกการสนทนาที่จะแสดง',
  agrochemical: 'เคมีเกษตร',
  livestock: 'ปศุสัตว์',
  agri_tools: 'อุปกรณ์เกษตร',
  durian: 'ทุเรียน',
  rice: 'ข้าว',
  corn: 'ข้าวโพด',
  longan: 'ลำไย',
  vegetable: 'ผัก',
  ornamental_plant: 'ไม้ดอกไม้ประดับ',
  how_to_order: 'วิธีการซื้อสินค้า',
  faq: 'คำถามที่พบบ่อย',
  terms_conditions: 'เงื่อนไขและข้อตกลง',
  buyer_protection_policy: 'นโยบายคุ้มครองผู้ซื้อ',
  seller_fa_policy: 'นโยบายบัญชีและการเงินผู้ขาย',
  supplier_fa_policy: 'นโยบายบัญชีและการเงินซัพพลายเออร์',
  seller_knowledge_center: 'ศูนย์ความรู้ผู้ขาย',
  wholesale_knowledge_center: 'ศูนย์ความรู้ค้าส่ง',
  farm_shop_knowledge_center: 'ศูนย์ความรู้ร้านค้าเกษตร',
  farm_knowledge_center: 'ศูนย์ความรู้ฟาร์ม',
  wholesale_price_msg:
    'ตลาดกลางค้าส่ง ปุ๋ย ยา เมล็ดพันธุ์ ปศุสัตว์ อุปกรณ์เกษตร ที่ใหญ่ที่สุดในไทย',
  buyer_help_center: 'ศูนย์ช่วยเหลือผู้ซื้อ',
  knowledge_center: 'ศูนย์ความรู้',
  we_accept: 'ช่องทางชำระเงิน',
  recent_contents: 'เนื้อหาล่าสุด',
  faq_for_buyer: 'คำถามที่พบบ่อยสำหรับผู้ซื้อ',
  seller_help_center: 'ศูนย์ช่วยเหลือผู้ขาย',
  faq_for_seller: 'คำถามที่พบบ่อยสำหรับผู้ขาย',
  seller_promotion_package: 'แพ็คเกจค้าขายร่ำรวย',
  contact_us: 'ติดต่อเรา',
  shop_by_category: 'ช้อปตามหมวดสินค้า',
  shop_by_crop: 'ช้อปตามชนิดของพืช',
  best_sellers: 'สินค้าขายดี',
  order_product: 'สินค้า',
  order_quantity: 'จำนวน',
  'Wait for Seller Feedback': 'รอการตอบกลับจากผู้ขาย',
  all_categories: 'หมวดหมู่ทั้งหมด',
  page_not_found: 'ไม่เจอข้อมูล',
  back_to_home_page: 'กลับไปหน้าแรก',
  Contact: 'ติดต่อเรา',
  negotiate: 'ต่อรอง',
  line_friend: 'เป็นเพื่อนกันเถอะ!',
  seller_logo_title: 'รวมแบรนด์ดังและแบรนด์ปั้นในราคาขายส่ง',
  select_payment_method: 'กรุณาเลือกช่องทางการชำระเงิน',
  qr_pay: 'QR พร้อมเพย์',
  expired: 'หมดอายุแล้ว',
  unpaid: 'ยังไม่ได้ชำระเงิน',
  delivered_step: 'ส่ง',
  i_agree: 'ยอมรับเงื่อนไข',
  amount_paid: 'จำนวนเงินที่ต้องชำระ',
  this_order_is_required_to_be: 'คำสั่งซื้อนี้จะ',
  credit_terms_is_in_effect: 'เงื่อนไขเครดิตต่อไปนี้มีผลบังคับใช้',
  order_is_can_be: 'ออเดอร์นี้ได้เลย',
  with_the_following_credit_terms: 'ด้วยเครดิตเทอม',
  order_confirmed_credit_terms:
    'คำสั่งซื้อได้รับการยืนยันโดยมีเงื่อนไขเครดิตดังต่อไปนี้',
  pay_now_and_get: 'ชำระเงินตอนนี้และรับ',
  discount_for: 'ส่วนลดสำหรับ',
  before: 'ก่อน',
  or: 'ก็ดี',
  pay_in_full_for: 'ชำระเงินเต็มจำนวนเพื่อr',
  all_banks_are_accepted: 'ใช้ได้ทุกธนาคาร',
  mobile_only: 'สำหรับโทรศัพท์เท่านั้น',
  desktop_and_mobile: 'สำหรับคอมพิวเตอร์และโทรศัพท์',
  to_be_with: 'ด้วยวิธีการ',
  post_code_validate: 'กรุณากรอกตัวเลขให้ครบ 13 หลัก',
  kyc_info: 'ข้อมูล KYC',
  user_info: 'ข้อมูลผู้ใช้',
  kyc_update_success: 'ความสำเร็จในการอัปเดต KYC',
  kyc_update_error: 'ข้อผิดพลาดในการอัปเดต KYC',
  buyer_kyc_submit_sucessfully: 'ผู้ซื้อส่ง kyc เรียบร้อยแล้ว',
  kgp_onboard_status: 'สถานะการส่งข้อมูลไปยังระบบรับชำระเงิน',
  kgp_onboard_submitted: 'ส่งแล้ว',
  kgp_onboard_not_submitted: 'ยังไม่ได้ส่ง',
  category_sales_target_error: 'ต้องตั้งเป้ายอดขายอย่างน้อย 1 หมวดสินค้า',
  jpg_png: 'เฉพาะไฟล์ jpg และ png',
  jpg_png_pdf: 'เฉพาะไฟล์ jpg png และ pdf',
  sales_summary_report: 'รายงานสรุปยอดขาย',
  sales_detail_report: 'รายงานรายละเอียดยอดขาย',
  report_year: 'ปีที่รายงาน'
};
export default words;
