import { request } from 'utils/axios';
import { convertThaiDateInput } from 'utils/format';

const AWS_BUCKET = process.env.REACT_APP_AWS_BUCKET;

function handleError(message, error) {
  if (error.response) {
    console.log(message, error.response.data);
    return error.response.data;
  }
  console.log(message, error);
  return { status: 500, message: '' };
}

export async function loginApi(userName, password) {
  const response = await request.request({
    method: 'POST',
    url: '/admin_user/login',
    data: {
      username: userName,
      password: password
    }
  });
  return response;
}

export async function registerApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/admin_user/create',
    data
  });
  return response;
}

export async function getAdminUserList() {
  const response = await request.request({
    method: 'POST',
    url: '/admin_user/get_list'
  });
  return response;
}

export async function getUserInfo(username) {
  const response = await request.request({
    method: 'POST',
    url: '/admin_user_management/get_info',
    data: { username }
  });
  return response.data;
}

export async function getBuyerInfo(username) {
  const response = await request.request({
    method: 'POST',
    url: '/admin_buyer_management/get_info',
    data: { username }
  });
  return response.data;
}

export async function getUserList() {
  const response = await request.request({
    method: 'POST',
    url: '/admin_user_management/list_seller'
  });
  return response.data;
}

export async function getBuyerList() {
  const response = await request.request({
    method: 'POST',
    url: '/admin_buyer_management/list_seller'
  });
  return response.data;
}

export async function confirmTokenApi() {
  const response = await request.request({
    method: 'GET',
    url: '/admin_user/validate'
  });
  return response;
}

export function uploadImageApi(files, onUploadProgress) {
  try {
    let formData = new FormData();
    files.forEach(file => {
      formData.append('files', file);
    });

    return request.post('/product/upload_image', formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress
    });
  } catch (error) {
    return handleError('uploadImageApi', error);
  }
}

export async function updateUserInfoApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/admin_user_management/update',
    data
  });
  return response.data;
}

export async function updateBuyerInfoApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/admin_buyer_management/update',
    data
  });
  return response.data;
}

export async function deleteUserInfoApi(username) {
  const response = await request.request({
    method: 'POST',
    url: '/admin_user_management/soft_delete',
    data: { username }
  });
  return response.data;
}

export async function restoreUserInfoApi(username) {
  const response = await request.request({
    method: 'POST',
    url: '/admin_user_management/admin_restore_user',
    data: { username }
  });
  return response.data;
}

export async function deleteBuyerInfoApi(username) {
  const response = await request.request({
    method: 'POST',
    url: '/admin_buyer_management/soft_delete',
    data: { username }
  });
  return response.data;
}

export async function restoreBuyerInfoApi(username) {
  const response = await request.request({
    method: 'POST',
    url: '/admin_buyer_management/admin_restore_user',
    data: { username }
  });
  return response.data;
}

export function getDataLocation(link) {
  return new Promise(resolve => {
    fetch(link)
      .then(x => x.json())
      .then(data => resolve(data))
      .catch(() => resolve([]));
  });
}

export async function changePasswordApi(username, password) {
  const response = await request.request({
    method: 'PUT',
    url: '/admin_user/change_password',
    data: {
      username,
      password
    }
  });
  return response.data;
}

export async function createProductApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/product/create',
    data
  });
  return response.data;
}

export async function updateProductApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/admin_product/update',
    data
  });
  return response.data;
}

export async function getProductApi(id) {
  const response = await request.request({
    method: 'POST',
    url: '/admin_product/get_info',
    data: { id }
  });
  return response.data;
}

export async function deleteProductApi(id) {
  const response = await request.request({
    method: 'POST',
    url: '/admin_product/soft_delete',
    data: { id }
  });
  return response.data;
}

export async function restoreProductApi(id) {
  const response = await request.request({
    method: 'POST',
    url: '/admin_product/admin_restore_product',
    data: { id }
  });
  return response.data;
}

export async function listProductApi(list_user = []) {
  const response = await request.request({
    method: 'POST',
    url: '/admin_product/list_product',
    data: { list_user }
  });
  return response.data;
}

export async function verifyPhoneApi(username, code) {
  const response = await request.request({
    method: 'POST',
    url: '/sms_verify/verify',
    data: {
      username,
      code
    }
  });
  return response.data;
}

export async function listOrderApi(params) {
  const response = await request.request({
    method: 'GET',
    url: '/admin/order_managements',
    params
  });
  return response.data;
}

export async function orderDetailApi(order_id) {
  const response = await request.request({
    method: 'GET',
    url: `/admin/order_managements/${order_id}`
  });
  return response.data;
}

export async function updateLogisticFeeApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/order_management/dms_update_logistic_fee',
    data
  });
  return response.data;
}

export async function confirmOrderApi(order_id) {
  const response = await request.request({
    method: 'POST',
    url: '/order_management/dms_confirm_order',
    data: { order_id }
  });
  return response.data;
}

export async function rejectOrderApi(order_id, message) {
  const response = await request.request({
    method: 'POST',
    url: '/order_management/dms_reject_order',
    data: {
      order_id,
      message
    }
  });
  return response.data;
}

export async function deliveryOrderApi(order_id) {
  const response = await request.request({
    method: 'POST',
    url: '/order_management/dms_completed_order',
    data: { order_id }
  });
  return response.data;
}

export async function agentListApi() {
  const response = await request.request({
    method: 'POST',
    url: '/agent/list_agent'
  });
  return response.data;
}

export async function createAgentAccountApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/agent/create_account',
    data
  });
  return response.data;
}

export async function updateAgentLevelApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/agent/update_agent_level',
    data
  });
  return response.data;
}

export async function deleteAgentLevelApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/agent/remove_relation',
    data
  });
  return response.data;
}

export async function dashboardReportApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/report/dms_report',
    data
  });
  return response.data;
}

export const getCurrentLocation = async () => {
  const response = await request.request({
    method: 'POST',
    url: '/location/get_data'
  });
  return response.data;
};

export async function priceByGpsListApi() {
  const response = await request.request({
    method: 'POST',
    url: '/agent/list_price_by_gps'
  });
  return response.data;
}

export async function createPriceByGpsApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/agent/create_location_agent_price',
    data
  });
  return response.data;
}

export async function updatePriceByGpsApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/agent/update_gps_price',
    data
  });
  return response.data;
}

export async function deleteLocationApi(gps_price_id) {
  const response = await request.request({
    method: 'POST',
    url: '/agent/delete_location',
    data: { gps_price_id }
  });
  return response.data;
}

export async function sellerListApi() {
  const response = await request.request({
    method: 'POST',
    url: '/admin_product/list_seller'
  });
  return response.data;
}

export async function getLogApi(start_time, end_time) {
  const response = await request.request({
    method: 'POST',
    url: '/admin_user/get_log',
    data: {
      start_time: convertThaiDateInput(start_time),
      end_time: convertThaiDateInput(end_time)
    }
  });
  return response.data;
}

export async function getKYCdata(data) {
  const response = await request.request({
    method: 'POST',
    url: '/kyc/get_seller_kyc_data',
    data
  });
  return response.data;
}

export async function getKYCBuyerData(data) {
  const response = await request.request({
    method: 'POST',
    url: '/kyc/get_buyer_kyc_data',
    data
  });
  return response.data;
}

export async function submitKyc(data) {
  const response = await request.request({
    method: 'POST',
    url: '/kyc/update_seller_kyc',
    data
  });
  return response.data;
}

export async function submitBuyerKyc(data) {
  const response = await request.request({
    method: 'POST',
    url: '/kyc/update_buyer_kyc',
    data: {
      ...data,
      total_target_fertilizer: +data.total_target_fertilizer,
      total_target_agro_chem: +data.total_target_agro_chem,
      total_target_seed: +data.total_target_seed,
      total_target_animal_feed: +data.total_target_animal_feed,
      total_target_animal_machinary_tools: +data.total_target_animal_machinary_tools,
    }
  });
  return response.data;
}

export async function adminSubmitKyc(data) {
  const response = await request.request({
    method: 'POST',
    url: '/kyc/admin_kyc_action',
    data
  });
  return response.data;
}

export async function adminBuyerSubmitKyc(data) {
  const response = await request.request({
    method: 'POST',
    url: '/kyc/admin_buyer_kyc_action',
    data
  });
  return response.data;
}

export async function getSellerKycListApi() {
  const response = await request.request({
    method: 'POST',
    url: '/kyc/get_seller_kyc_list'
  });
  return response.data;
}

export async function getBuyerKycListApi() {
  const response = await request.request({
    method: 'POST',
    url: '/kyc/get_buyer_kyc_list'
  });
  return response.data;
}

export async function addOptionChoice(data) {
  const response = await request.request({
    method: 'POST',
    url: '/product/add_common_name_data',
    data
  });
  return response.data;
}

export async function getListChoice() {
  const response = await request.request({
    method: 'POST',
    url: '/product/get_choice'
  });
  return response.data;
}

export async function listFeeOptionsApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/fee_management/get_fee_options',
    data
  });
  return response.data;
}

export async function createFeeOptionApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/fee_management/create',
    data
  });
  return response.data;
}

export async function updateFeeOptionApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/fee_management/update',
    data
  });
  return response.data;
}

export async function getFeeOptionApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/fee_management/get_fee_option',
    data
  });
  return response.data;
}

export async function deleteFeeOptionApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/fee_management/delete_fee_option',
    data
  });
  return response.data;
}

export async function softDeleteFeeOptionApi(fee_id) {
  const response = await request.request({
    method: 'POST',
    url: '/fee_management/soft_delete',
    data: { fee_id }
  });
  return response.data;
}

export async function restoreFeeOptionApi(fee_id) {
  const response = await request.request({
    method: 'POST',
    url: '/fee_management/admin_restore_fee_option',
    data: { fee_id }
  });
  return response.data;
}

export async function listFeeOptionsIndiApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/fee_management_indi/get_fee_options',
    data
  });
  return response.data;
}

export async function getFeeOptionIndiApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/fee_management_indi/get_fee_option',
    data
  });
  return response.data;
}

export async function createFeeOptionIndiApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/fee_management_indi/create',
    data
  });
  return response.data;
}

export async function updateFeeOptionIndiApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/fee_management_indi/update',
    data
  });
  return response.data;
}

export async function deleteFeeOptionIndiApi(data) {
  const response = await request.request({
    method: 'POST',
    url: '/fee_management_indi/delete_fee_option',
    data
  });
  return response.data;
}

export async function softDeleteFeeOptionIndiApi(fee_id) {
  const response = await request.request({
    method: 'POST',
    url: '/fee_management_indi/soft_delete',
    data: { fee_id }
  });
  return response.data;
}

export async function restoreFeeOptionIndiApi(fee_id) {
  const response = await request.request({
    method: 'POST',
    url: '/fee_management_indi/admin_restore_fee_option',
    data: { fee_id }
  });
  return response.data;
}

export function getDataOrigin() {
  return new Promise(resolve => {
    fetch(
      `https://${AWS_BUCKET}.s3.ap-southeast-1.amazonaws.com/list_countries2.json`
    )
      .then(x => x.json())
      .then(data => resolve(data))
      .catch(() => resolve([]));
  });
}

export async function forgotPasswordApi(username) {
  const response = await request.request({
    method: 'POST',
    url: '/sms_verify/send_forget_password',
    data: {
      username
    }
  });
  return response.data;
}

export async function changeOtpApi(username, code) {
  const response = await request.request({
    method: 'POST',
    url: '/sms_verify/verify_new_password',
    data: {
      username,
      code
    }
  });
  return response.data;
}

export async function getKgpProfessions() {
  const response = await request.request({
    method: 'GET',
    url: 'dms/kgp/professions'
  });

  return response.data;
}

export async function getKgpOccupations() {
  const response = await request.request({
    method: 'GET',
    url: '/dms/kgp/occupations'
  });

  return response.data;
}
