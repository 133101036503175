import * as actionTypes from 'actions';

const initialState = {
  loggedIn: true,
  user: {
    first_name: 'Tùng',
    last_name: 'Nguyễn',
    email: 'tungns276@gmail.com',
    avatar: '',
    bio: 'Supper admin',
    role: 'ADMIN', // ['GUEST', 'USER', 'ADMIN'],
    phone_number: '0123456789',
    address: 'hh3c Linh Đàm, Hoàng Liệt, Hoàng Mai, Hà Nội',
    description: 'test',
    bank_information: {
      bank_brand: 'VPbank',
      card_number: '*******12345'
    }
  },
  location: null,
  locationData: [],
  currency: '฿'
};

const sessionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SESSION_LOGIN: {
      return {
        ...initialState
      };
    }

    case actionTypes.SESSION_LOGOUT: {
      return {
        ...state,
        loggedIn: false,
        user: {
          role: 'GUEST'
        }
      };
    }

    case actionTypes.SESSION_SET_INFO: {
      let newCurrency = state.currency;
      if (action.data?.default_region) {
        newCurrency = action.data?.default_region === 'vn' ? '₫' : '฿';
      }
      console.log(12312312312312, { ...state.user, ...action.data }, action.data)
      return {
        ...state,
        loggedIn: false,
        user: { ...state.user, ...action.data },
        currency: newCurrency
      };
    }

    case actionTypes.SESSION_SET_LOCATION: {
      return {
        ...state,
        location: action.data
      };
    }

    case actionTypes.SESSION_UPDATE_LOCATION_DATA: {
      return {
        ...state,
        locationData: action.data
      };
    }

    case actionTypes.SET_USER_STATUS: {
      return {
        ...state,
        userStatus: action.data
      };
    }

    case actionTypes.SET_TIME_USER_STATUS: {
      return {
        ...state,
        kycInfo: action.data,
      };
    }

    default: {
      return state;
    }
  }
};

export default sessionReducer;
