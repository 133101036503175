import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import {
  colors,
  useMediaQuery,
  useTheme,
  Grid,
  CircularProgress,
  IconButton
} from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { ImageDetail } from './components';
import { uploadImageApi } from 'apis';
import { t } from 'locale';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper
  },
  title: {
    color: theme.palette.primary.light
  },
  titleBar: {
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
  },
  image: {
    top: 0,
    transform: 'translateY(0)'
  },
  box: {
    position: 'relative'
  },
  dropZone: {
    border: `1px dashed ${theme.palette.divider}`,
    padding: theme.spacing(2),
    outline: 'none',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    '&:hover': {
      backgroundColor: colors.grey[50],
      opacity: 0.5,
      cursor: 'pointer'
    }
  },
  deleteButton: {
    position: 'absolute',
    right: 0,
    zIndex: 10,
    padding: 2,
    backgroundColor: colors.grey[100]
  },
  dragActive: {
    backgroundColor: colors.grey[50],
    opacity: 0.5
  },
  item: {
    width: 'auto !important'
  },
  divCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const Images = ({
  images = [],
  updateImage,
  onRemove,
  disableAdd,
  maxLength = 20
}) => {
  const [openFile, setOpenFile] = React.useState(false);
  const [{ image, imageIndex }, setImage] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const classes = useStyles();

  const handleClose = () => {
    setOpenFile(false);
  };
  const handleOpen = (image, imageIndex) => {
    setImage({
      image,
      imageIndex
    });
    setOpenFile(true);
  };

  const handleDrop = React.useCallback(
    (acceptedFiles, fileRejections) => {
      if (fileRejections.length) {
        alert(t('image_upload_max_file_size_error'));
        return;
      }
      if (acceptedFiles.length + images.length > maxLength) {
        alert(t('max_image_upload', { maxLength }));
        return;
      }
      setLoading(true);
      uploadImageApi(acceptedFiles, event => {
        console.log(Math.round((100 * event.loaded) / event.total));
      })
        .then(res => {
          updateImage && updateImage(res.data);
          setLoading(false);
        })
        .catch(() => {
          alert(t('image_upload_error'));
        });
    },
    [updateImage]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    maxSize: 5000000
  });

  return (
    <div className={classes.root}>
      <ImageDetail
        imageIndex={imageIndex}
        image={image}
        onClose={handleClose}
        open={openFile}
        onRemove={onRemove}
      />
      <Grid container spacing={1}>
        {images.map((item, key) => (
          <Grid item key={key} className={classes.box}>
            <IconButton
              className={classes.deleteButton}
              onClick={() => onRemove(key)}>
              <HighlightOffIcon color="error" />
            </IconButton>
            <img
              src={item}
              height={mobileDevice ? 68 : 168}
              alt={item}
              className={classes.image}
              onClick={() => handleOpen(item, key)}
            />
          </Grid>
        ))}
        {!disableAdd && (
          <Grid item>
            {loading ? (
              <div
                style={{ height: mobileDevice ? 68 : 130 }}
                className={classes.divCenter}>
                <CircularProgress />
              </div>
            ) : (
              <div
                className={clsx(
                  {
                    [classes.dropZone]: true,
                    [classes.dragActive]: isDragActive
                  },
                  classes.item
                )}
                {...getRootProps()}>
                <input {...getInputProps()} />
                <div>
                  <img
                    alt={t('select_file')}
                    height={mobileDevice ? 30 : 130}
                    src="/images/undraw_add_file2_gvbb.svg"
                  />
                </div>
              </div>
            )}
          </Grid>
        )}
      </Grid>
    </div>
  );
};
export default Images;
