// import { getUserInfo } from 'apis';

import { getUserInfo } from 'apis';

export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';
export const SESSION_SET_INFO = 'SESSION_SET_INFO';
export const SESSION_UPDATE_LOCATION_DATA = 'SESSION_UPDATE_LOCATION_DATA';
export const SESSION_SET_LOCATION = 'SESSION_SET_LOCATION';
export const SET_TIME_USER_STATUS = 'SET_TIME_USER_STATUS';
export const SET_USER_STATUS = 'SET_USER_STATUS';


export const login = data => async dispatch => {
  window.localStorage.setItem('admin_agrimomo_access_token', data.access_token);
  // const userInfo = await getUserInfo(data.first_name);
  dispatch(setInfo({ username: data.first_name }));
  dispatch({
    type: SESSION_LOGIN,
    data
  });
};

export const logout = () => dispatch => {
  window.localStorage.setItem('admin_agrimomo_access_token', '');
  window.localStorage.setItem('admin_agrimomo_username', '');
  dispatch({
    type: SESSION_LOGOUT
  });
};

export const setInfo = data => dispatch => {
  window.localStorage.setItem('admin_agrimomo_username', data.username);
  dispatch(updateInfo(data));
};

export const updateInfo = data => dispatch => {
  dispatch({
    type: SESSION_SET_INFO,
    data
  });
};

export const setLocation = data => dispatch => {
  dispatch({
    type: SESSION_SET_LOCATION,
    data
  });
};

export const updateLocationData = data => dispatch => {
  dispatch({
    type: SESSION_UPDATE_LOCATION_DATA,
    data
  });
};

export const setUserStatus = data => dispatch => {
  dispatch({
    type: SET_USER_STATUS,
    data
  });
};

export const setTimeUserStatus = data => dispatch => {
  dispatch({
    type: SET_TIME_USER_STATUS,
    data
  });
};
