import { t } from 'locale';
import HomeIcon from '@material-ui/icons/HomeOutlined';
// import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import StorageIcon from '@material-ui/icons/Storage';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import MktFeeOptionsIcon from '@material-ui/icons/AttachMoney';

export default [
  {
    title: 'Pages',
    pages: [
      {
        title: t('overview'),
        href: '/overview',
        icon: HomeIcon
      },
      {
        title: t('product_management'),
        href: '/product-management',
        icon: StorageIcon
      },
      {
        title: t('order_management'),
        href: '/order-management',
        icon: ShoppingCartIcon
      },
      {
        title: 'Chat',
        href: '/chat',
        icon: ChatIcon
        // label: () => (
        //   <Label color={colors.red[500]} shape="rounded">
        //     4
        //   </Label>
        // )
      },
      {
        title: t('seller_management'),
        href: '/user-management',
        icon: PermIdentityIcon
      },
      {
        title: t('buyer_management'),
        href: '/buyer-management',
        icon: PermIdentityIcon
      },
      {
        title: t('kyc_seller_management'),
        href: '/kyc-seller-management',
        icon: VpnKeyIcon
      },
      {
        title: t('kyc_buyer_management'),
        href: '/kyc-buyer-management',
        icon: VpnKeyIcon
      },
      {
        title: t('mkt_fee_options'),
        href: '/fee-management',
        icon: MktFeeOptionsIcon
      }
      // {
      //   title: t('farm_shop_management'),
      //   href: '/farm-shop-management',
      //   icon: StoreIcon
      // },
      // {
      //   title: t('price_by_gps_management'),
      //   href: '/price-by-gps-management',
      //   icon: PinDropIcon
      // },
      // {
      //   title: t('settings'),
      //   href: '/settings',
      //   icon: SettingsIcon,
      //   children: [

      //     {
      //       title: t('general_setting'),
      //       href: '/settings/general'
      //     },
      //     {
      //       title: t('change_password'),
      //       href: '/settings/security'
      //     }
      //   ]
      // },
      // {
      //   title: 'Authentication',
      //   href: '/auth',
      //   icon: LockOpenIcon,
      //   children: [
      //     {
      //       title: 'Login',
      //       href: '/auth/login'
      //     },
      //     {
      //       title: 'Register',
      //       href: '/auth/register'
      //     }
      //   ]
      // },
    ]
  }
];
