import React from 'react';
import { makeStyles } from '@material-ui/styles';

import { Page } from 'components';
import { Header } from './components';
import { Grid } from '@material-ui/core';
import TableVertical from 'components/TableVertical';
import { getLogApi } from 'apis';
import { t } from 'locale';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    width: theme.breakpoints.values.lg,
    maxWidth: '100%',
    margin: '0 auto',
    padding: theme.spacing(3)
  },
  statistics: {
    marginTop: theme.spacing(3)
  },
  container: {
    marginTop: theme.spacing(3)
  }
}));

const Overview = () => {
  const classes = useStyles();

  const [start_time, setStartTime] = React.useState(
    moment(new Date())
      .subtract(3, 'month')
      .toDate()
  );
  const [end_time, setEndTime] = React.useState(new Date());
  const [data, setData] = React.useState([]);

  const fetchData = async () => {
    const res = await getLogApi(start_time, end_time);
    setData(res);
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <Page className={classes.root} title={t('overview_title')}>
      <Header
        start_time={start_time}
        end_time={end_time}
        setStartTime={setStartTime}
        setEndTime={setEndTime}
        fetchData={fetchData}
      />
      <Grid className={classes.container} container spacing={3}>
        <Grid item lg={12} xl={12} xs={12}>
          <TableVertical list={data || []} />
        </Grid>
      </Grid>
    </Page>
  );
};

export default Overview;
