const words = {
  select_language: 'Chọn ngôn ngữ',
  management: 'Quản lý',
  product: 'Sản phẩm',
  product_management: 'Quản lý sản phẩm',
  all: 'Tất cả',
  phan_bon: 'Phân bón',
  hat_giong: 'Hạt giống',
  dung_cu: 'Dụng cụ',
  khac: 'Khác',
  local_name: 'Tên sản phẩm',
  status: 'Trạng thái',
  price: 'Giá',
  inventory_num: 'Số lượng hàng tồn kho',
  expiry_date: 'Ngày hết hạn',
  action: 'Hoạt động',
  create_product: 'Tạo sản phẩm',
  upload_by_excel: 'Tải lên bởi Excel',
  product_code: 'Mã sản phẩm',
  product_code_helper: 'SKU',
  ean: '13 số của mã EAN (nếu có)',
  ean_helper: 'Số chữ số 13 chữ số',
  local_name_english: 'Tên tiếng anh',
  local_name_helper: 'Giúp đỡ về tên sản phẩm tiếng Việt',
  category: 'Danh mục',
  sub_category: 'Danh mục phụ',
  phone_number: 'Số điện thoại',
  password: 'Mật khẩu',
  login_false: 'Đăng nhập thất bại',
  login_success: 'Đăng nhập thành công',
  update_product_id: 'Cập nhật sản phẩm: {id}',
  update_product: 'Cập nhật sản phẩm',
  hoa_chat: 'Hóa chất',
  thuc_an_dong_vat: 'Thức ăn động vật',
  sku: 'SKU',
  sku_helper:
    'SKU phải là duy nhất và chỉ dành cho tài liệu tham khảo của riêng bạn vì nó không phải là số được chỉ định hệ thống',
  eng_name: 'Tên tiếng anh',
  product_type: 'Loại',
  brand_name: 'Mã người dùng',
  brand_name_helper: 'Tên thương hiệu',
  origin: 'Nguồn gốc',
  origin_helper: 'Xuất xứ',
  unit_type: 'Loại đơn vị',
  general_des: 'Mô tả chung',
  general_des_helper: 'Tổng quan về sản phẩm',
  tech_des: 'Mô tả kỹ thuật',
  tech_des_helper: 'Mô tả kỹ thuật về sản phẩm',
  manual_guide: 'Cách sử dụng',
  manual_guide_helper: 'Cách sử dụng',
  inventory_num_helper: 'Số lượng',
  price_helper: 'Giá đã bao gồm VAT',
  note: 'Ghi chú',
  on_hand_date: 'Ngày lưu kho',
  group_1_price: 'Giá cho nhóm 1',
  group_2_price: 'Giá cho nhóm 2',
  group_3_price: 'Giá cho nhóm 3',
  group_1_quantity: 'Số lượng cho nhóm 1',
  group_2_quantity: 'Số lượng cho nhóm 2',
  group_3_quantity: 'Số lượng cho nhóm 3',
  curreny: 'Tiền tệ',
  curreny_helper: 'Tiền tệ được cài đặt tự động',
  note_helper: 'Giúp đỡ về Ghi chú',
  on_hand_date_helper: 'Ngày lưu kho',
  warranty_note_helper: 'Giúp đỡ về Lưu ý bảo hành',
  group_1_price_helper: 'Giảm giá cho nhóm 1',
  group_1_quantity_helper: 'Tổng số lượng cho nhóm 1',
  group_2_price_helper: 'Giảm giá cho nhóm 2',
  group_2_quantity_helper: 'Tổng số lượng cho nhóm 2',
  group_3_price_helper: 'Giảm giá cho nhóm 3',
  group_3_quantity_helper: 'Tổng số lượng cho nhóm 3',
  level_1_helper: ' ',
  level_2_helper: ' ',
  level_3_helper: ' ',
  level_4_helper: ' ',
  level_5_helper: ' ',
  warranty_note: 'Lưu ý bảo hành',
  vat: 'VAT',
  expiry_list: 'Danh sách ngày hết hạn',
  settings: 'Cài đặt',
  general_setting: 'Thông tin người dùng',
  change_password: 'Đổi mật khẩu',
  wholesaler_information: 'Thông tin người dùng',
  email: 'Email',
  city: 'Tỉnh/thành phố',
  district: 'Huyện',
  ward: 'Phường/xã',
  post_code: 'Mã vùng',
  main_category: 'Sản phẩm chính',
  images: 'Hình ảnh',
  add_link_video: 'Liên kết video',
  bank_account_information: 'Thông tin tài khoản ngân hàng',
  upload_image: 'Tải lên',
  save_change: 'Lưu',
  confirm_password: 'Xác nhận mật khẩu',
  password_update_success: 'Cập nhật mật khẩu thành công',
  password_update_error: 'Đổi mật khẩu thất bại',
  user_update_success: 'Người dùng cập nhật thành công',
  user_update_false: 'Người dùng cập nhật không thành công',
  product_update_success: 'Cập nhật thành công',
  product_create_success: 'Tạo ra thành công',
  product_create_error: 'Đã tạo ra thất bại',
  product_update_error: 'Cập nhật không thành công',
  price_by_agent_level: 'Giá phân cấp đại lý',
  user_local_name: 'Tên',
  user_name: 'Tên tài khoản',
  start_time: 'Thời gian bắt đầu',
  end_time: 'Thời gian kết thúc',
  created_time: 'đã tạo lúc',
  log: 'Log',
  type: 'Loại',
  SellerManagement: 'Quản lý người bán',
  BuyerManagement: 'Quản lý người mua',
  SellerKYCManagement: 'Người bán quản lý KYC',
  BuyerKYCManagement: 'Người mua quản lý KYC',
  mkt_fee_options: 'Quản lý phí toàn cầu',
  send_all_chat: 'Gửi tất cả các cuộc trò chuyện',
  SellerUser: 'Người bán',
  Profile: 'Hồ sơ',
  buyer_setting: 'Cài đặt người mua',
  buyer_information: 'Thông tin người mua',
  buyer_kyc: 'KYC người mua',
  fertilizer: 'Phân bón',
  chemical: 'Hoá chất nông nghiệp',
  seed: 'Hạt giống',
  animal_feed: 'Thức ăn chăn nuôi',
  animal_food: 'Thức ăn chăn nuôi',
  tool: 'Dụng cụ và máy nông nghiệp',
  other: 'Danh mục khác',
  original_price: 'Giá gốc',
  overview_title: 'Tổng quan',
  hello_mes: 'Bảng điều khiển',
  good_morning: 'Xin chào',
  filter_date: 'Ngày lọc',
  filter_button: 'Lọc',
  total_sell_quantity: 'Tổng số lượng bán',
  total_sales: 'Tổng đã bán',
  dashboard: 'Bảng điều khiển',
  product_name: 'Tên sản phẩm',
  buyer_name: 'Tên người mua',
  total_price: 'Tổng giá',
  total_quantity: 'Tổng số lượng',
  see_all: 'Nhìn thấy tất cả',
  max_image_upload: 'Bạn chỉ có thể tải lên các tệp {maxLength}',
  max_file_size_upload:
    'Bạn chỉ có thể tải lên kích thước tệp tối đa của {maxFilesize} MB',
  image_upload_error: 'Lỗi tải lên hình ảnh',
  select_file: 'Chọn tập tin',
  product_local_name: 'Tên sản phẩm',
  product_local_name_helper: 'Giúp đỡ về tên sản phẩm',
  product_eng_name: 'Tên sản phẩm bằng tiếng Anh',
  product_eng_name_helper: 'Giúp đỡ về tên sản phẩm bằng tiếng Anh',
  product_common_name: 'Tên thông dụng của sản phẩm',
  product_common_name_helper: 'Giúp đỡ về Tên thông dụng của sản phẩm',
  product_manufacturer_name: 'Tên nhà sản xuất sản phẩm',
  product_manufacturer_name_helper: 'Giúp đỡ về Tên nhà sản xuất sản phẩm',
  product_importer_name: 'Tên nhà nhập khẩu sản phẩm',
  product_importer_name_helper: 'Giúp đỡ về Tên nhà nhập khẩu sản phẩm',
  product_distributor_name: 'Tên nhà phân phối sản phẩm',
  product_distributor_name_helper: 'Giúp đỡ về Tên nhà phân phối sản phẩm',
  price_exclude_vat_helper: 'Giá chưa bao gồm VAT',
  expiry_list_expridate: 'Ngày hết hạn',
  expridate_helper: 'Ngày hết hạn',
  expiry_list_quantity: 'Số lượng danh sách hết hạn',
  expiry_list_quantity_helper: 'Giúp đỡ về Số lượng danh sách hết hạn',
  status_active: 'Hoạt động',
  status_inactive: 'Không hoạt động',
  yes: 'Có',
  no: 'Không',
  agent_level_1: 'Cấp độ 1',
  agent_level_1_helper: 'Chiết khấu % cho đại lý cấp 1',
  agent_level_2: 'Cấp độ 2',
  agent_level_2_helper: 'Chiết khấu % cho đại lý cấp 2',
  agent_level_3: 'Cấp độ 3',
  agent_level_3_helper: 'Chiết khấu % cho đại lý cấp 3',
  agent_level_4: 'Cấp độ 4',
  agent_level_4_helper: 'Chiết khấu % cho đại lý cấp 4',
  agent_level_5: 'Cấp độ 5',
  agent_level_5_helper: 'Chiết khấu % cho đại lý cấp 5',
  cancel: 'Hủy bỏ',
  product_delete_confirm: 'Xác nhận xoá',
  load_more: 'Hiển thị thêm',
  order_management: 'Quản lý đơn hàng',
  order: 'Danh sách',
  inquiry_created: 'Yêu cầu được tạo',
  wait_for_update_fee: 'Chờ phản hồi của người bán',
  dms_reject: 'Người bán từ chối',
  farm_shop_reject: 'Người mua từ chối',
  farmshop_bank_confirm: 'Người mua xác nhận thanh toán',
  wait_for_farmshop_confirm: 'Chờ phản hồi của người mua',
  on_progress: 'Tiến hành',
  confirm_order_success: 'Xác nhận thành công',
  confirm_order_error: 'Xác nhận lỗi',
  delivery_order_success: 'Giao hàng thành công',
  delivery_order_error: 'Giao hàng thất bại',
  confirm: 'Xác nhận',
  delivery: 'Vận chuyển',
  order_by: 'Đặt hàng bởi',
  more: 'nhiều hơn',
  farm_shop_management: 'Quản lý đại lý',
  farm_shop: 'Danh sách đại lý',
  create_farm_shop_account: 'Tạo mới',
  farm_name: 'Tên đại lý',
  username: 'Số điện thoại',
  agent_level: 'Cấp độ',
  total_order: 'Tổng đã bán',
  last_order: 'Ngày đặt hàng cuối cùng',
  avatar_update_false: 'Cập nhật hình đại diện không thành công',
  user_eng_name: 'Tên tiếng anh',
  user_general_des: 'Mô tả chung',
  video: 'Video',
  update_bank_information: 'Cập nhật thông tin ngân hàng',
  bank_name: 'Tên ngân hàng',
  account_number: 'Số tài khoản',
  owner_name: 'Tên tài khoản',
  ok: 'Ok',
  password_update_false: 'Cập nhật mật khẩu không thành công',
  sign_out: 'Đăng xuất',
  sign_in: 'Đăng nhập',
  is_required: 'bắt buộc',
  do_not_have_account: 'Tên ngân hàng',
  have_account: 'Have an account?',
  verify_code: 'Mã xác minh',
  register_false: 'Đăng ký thất bại',
  register_success: 'Đăng ký thành công',
  code: 'Mã OTP',
  create_account: 'Tạo tài khoản',
  back_home: 'Trở về Trang chủ',
  copy_to_clipboard: 'Sao chép vào clipboard',
  field_required: 'Trường này là bắt buộc',
  price_by_gps_management: 'Giá theo vị trí',
  price_by_gps: 'Giá theo vị trí',
  list_province: 'Danh sách các tỉnh',
  gps_price: 'Giá tại vị trí đã chọn',
  gps_price_delete_confirm: 'Bạn có chắc chắn muốn xoá?',
  please_select: 'Vui lòng chọn 1 tùy chọn',
  image_upload_max_file_size_error: 'Kích thước tệp phải nhỏ hơn 5 MB',
  product_id: 'ID sản phẩm',
  price_helper_validate_price: 'Giá phải nhỏ hơn Giá niêm yết',
  group_1_price_helper_validate_price: 'Giá phải nhỏ hơn giá hiện tại',
  group_2_price_helper_validate_price: 'Giá phải nhỏ hơn giá nhóm 1',
  group_3_price_helper_validate_price: 'Giá phải nhỏ hơn giá nhóm 2',
  group_1_quantity_helper_validate_price:
    'Số lượng phải lớn hơn số lượng đơn hàng tối thiểu',
  group_2_quantity_helper_validate_price:
    'Số lượng phải lớn hơn số lượng nhóm 1',
  group_3_quantity_helper_validate_price:
    'Số lượng phải lớn hơn số lượng nhóm 2',
  attach_registeration_file: 'Tối đa 5 tệp, 5mb mỗi tệp',
  attach_file: 'Đính kèm tệp đăng ký',
  sku_existed: 'SKU đã tồn tại',
  min_order_quantity: 'Số lượng đặt hàng tối thiểu',
  min_order_quantity_helper: 'Giúp đỡ về số lượng đặt hàng tối thiểu',
  original_price_helper: 'Giá niêm yết phải lớn hơn Giá hiện tại',
  credit_term_helper: 'Thời hạn tín dụng phải là số nguyên',
  credit_term: 'Hạn tín dụng',
  cost_price_helper: 'Giúp đỡ về Giá thành sản phẩm',
  cost_price: 'Giá thành sản phẩm',
  product_private:
    'Có phải sản phẩm này chỉ hiển thị cho các đại lý được chọn không?',
  organic_product: 'Đây có phải là một sản phẩm hữu cơ?',
  crop_or_animal: 'Chọn Cây trồng hoặc Động vật',
  type_of_crop: 'Loại cây trồng',
  type_of_crop_helper: 'Chèn loại cây trồng',
  growth_stage_of_crop: 'Giai đoạn sinh trưởng của cây trồng',
  growth_stage_of_animal: 'Giai đoạn sinh trưởng của động vật',
  type_of_pest: 'Loại sâu bệnh',
  type_of_disease_helper: 'Giúp đỡ về Loại bệnh cây trồng',
  type_of_disease: 'Loại bệnh cây trồng',
  type_of_weed_helper: 'Giúp đỡ về Loại cỏ dại',
  type_of_weed: 'Loại cỏ dại',
  registration_year_helper: 'Giúp đỡ về Năm đăng ký',
  registration_year: 'Năm đăng ký',
  registration_number_helper: 'Giúp đỡ về Số đăng ký',
  registration_number: 'Số đăng ký',
  most_recent_registration_renewal_date_helper:
    'Giúp đỡ về Ngày gia hạn đăng ký gần đây nhất',
  most_recent_registration_renewal_date: 'Ngày gia hạn đăng ký gần đây nhất',
  invoice: 'Hóa đơn',
  update_invoice: 'Cập nhật hóa đơn',
  begin_delivery: 'Bắt đầu giao hàng',
  update_invoice_success: 'Cập nhật hóa đơn thành công',
  update_invoice_error: 'Cập nhật hóa đơn lỗi',
  dialog_confirm_delivery:
    'Vui lòng xác nhận bạn đã nhận được khoản thanh toán đầy đủ trước khi giao hàng',
  confirm_delivery_success: 'Xác nhận giao hàng thành công',
  confirm_delivery_error: 'Xác nhận giao hàng lỗi',
  delivery_terms: 'Điều khoản giao hàng',
  delivery_terms_desc:
    'Đơn đặt hàng này sẽ được người bán giao cho người mua trong vòng 2 đến 5 ngày',
  payment_terms: 'Điều khoản thanh toán',
  payment_terms_1: 'Đơn đặt hàng này phải được thanh toán trước',
  payment_terms_2: 'Đơn đặt hàng được thanh toán bằng tiền mặt khi giao hàng',
  payment_terms_3:
    'Đơn đặt hàng có thể được thanh toán sau theo các điều khoản tín dụng',
  payment_terms2_note:
    'Vui lòng đảm bảo chuyển phát nhanh được chỉ định cung cấp tiền mặt cho dịch vụ giao hàng',
  reject_order_note: 'Bạn có chắc chắn muốn từ chối đơn đặt hàng này?',
  rule:
    'Tôi thừa nhận rằng Agrimomo sẽ không chịu, bất kỳ trường hợp nào, phải chịu trách nhiệm về các thiệt hại do hậu quả, ngẫu nhiên, đặc biệt, trừng phạt hoặc mẫu mực phát sinh từ hoặc liên quan đến giao dịch này bao gồm lợi nhuận bị mất hoặc mất doanh nghiệp.',
  pre_paid:
    'Đơn đặt hàng được yêu cầu phải được thanh toán trước khi giao hàng',
  wait_for_confirm: 'Chờ phản hồi của người mua',
  cash_on_delivery: 'Đơn đặt hàng được thanh toán bằng tiền mặt khi giao hàng',
  note_cash_on_delivery:
    'Vui lòng đảm bảo chuyển phát nhanh được chỉ định cung cấp tiền mặt cho dịch vụ giao hàng',
  credit_term_title:
    'Đơn đặt hàng này có thể được thanh toán sau đó bằng các điều khoản tín dụng sau',
  note_credit_term:
    'Điều này có nghĩa là giảm giá {credit_term_discount}% nếu được trả trong vòng {credit_term_period} ngày kể từ ngày đặt hàng. Nếu không, khoản thanh toán đầy đủ sẽ được thực hiện trong vòng {payment_due_in_days} ngày kể từ ngày đặt hàng.',
  wholesale_img: 'Tải lên hình ảnh sản phẩm trong đơn vị bán buôn',
  prod_img: 'Tải lên hình ảnh sản phẩm trong đơn vị bán lẻ',
  check_blank: 'Vui lòng điền đầy đủ thông tin',
  check_price: 'Vui lòng kiểm tra giá trong hộp màu đỏ',
  check_image:
    'Vui lòng tải lên cả hình ảnh trong đơn vị bán lẻ và hình ảnh trong đơn vị bán buôn',
  expiry_list_batch_number_helper: 'Giúp đỡ về Số lô',
  expiry_list_batch_number: 'Số lô',
  contact_person_name_eng: 'Người liên hệ (tiếng Anh)',
  seller_name_local: 'Tên người bán (ngôn ngữ địa phương)',
  seller_name_eng: 'Tên người bán (tiếng Anh)',
  contact_person_name_local: 'Người liên hệ (tiếng Việt)',
  contact_person_position: 'Vị trí người liên hệ',
  contact_person_profile_picture: 'Hình ảnh người liên hệ',
  address: 'Địa chỉ',
  seller_brand_logo: 'Logo thương hiệu người bán',
  copy_of_id_card_pass_port:
    'Bản sao thẻ ID hoặc hộ chiếu đại diện được ủy quyền + có chữ ký của đại diện được ủy quyền',
  copy_of_bank_account_book:
    'Bản sao của sổ tài khoản ngân hàng + có chữ ký của đại diện được ủy quyền',
  copy_of_source_document:
    'Bản sao của tài liệu nguồn sản phẩm, ví dụ: Hợp đồng mua bán và bán hàng, hợp đồng bổ nhiệm đại lý bán hàng, hợp đồng sản xuất, giấy phép thiết lập nhà máy hoặc thư giải phóng mặt bằng của các cơ quan có liên quan (Không bắt buộc)',
  copy_of_company_affidavit:
    'Bản sao của Công ty Bản khai (không hơn 3 tháng) + có chữ ký của Đại diện được ủy quyền',
  copy_of_value_added_tax:
    'Bản sao thuế giá trị gia tăng (tùy chọn) + có chữ ký của đại diện được ủy quyền',
  copy_of_power_of_attorney: 'Bản sao giấy ủy quyền (không bắt buộc)',
  hear_about_agrimomo: 'Làm thế nào bạn biết về Agrimomo?',
  delete: 'xoá',
  max_file_size_upload_title: 'Tối đa 5 tệp, 5mb mỗi tệp',
  form_product_cancel_confirm:
    'Bạn sẽ mất tất cả dữ liệu chưa được lưu. Bạn có chắc không?',
  credit_term_offered: 'Thời hạn tín dụng được cung cấp',
  check_file: 'cần tải lên các tập tin cần thiết',
  discount_percent: 'Chiết khấu %',
  max_discount_day: 'Ngày giảm giá tối đa (từ ngày đặt hàng)',
  max_pay_day: 'Ngày trả lương tối đa (từ ngày đặt hàng)',
  type_of_animal: 'Loại vật nuôi',
  type_of_animal_disease: 'Loại bệnh vật nuôi',
  general_seller_description: 'Mô tả chung của người bán',
  annual_revenue: 'Doanh thu hàng năm',
  annual_revenue_helper:
    'Tổng doanh thu hàng năm bao gồm tất cả trực tuyến và ngoại tuyến',
  total_target_sales_on_amm_per_month:
    'Tổng doanh số mục tiêu trên Agrimomo (mỗi tháng)',
  total_target_fertilizer: 'Tổng doanh số mục tiêu cho phân bón',
  total_target_fertilizer_helper:
    'Giúp đỡ về Tổng doanh số mục tiêu cho phân bón',
  total_target_agro_chem: 'Tổng doanh số mục tiêu cho hóa chất hóa học',
  total_target_seed: 'Tổng doanh số mục tiêu cho hạt giống',
  total_target_seed_helper: 'Giúp đỡ về Tổng doanh số mục tiêu cho hạt giống',
  total_target_animal_feed: 'Tổng doanh số mục tiêu cho chăn nuôi',
  total_target_animal_feed_helper:
    'Giúp đỡ về Tổng doanh số mục tiêu cho chăn nuôi',
  total_target_animal_machinary_tools:
    'Tổng doanh số mục tiêu cho các công cụ Agri',
  total_target_machinery_tools_helper:
    'Giúp đỡ về Tổng doanh số mục tiêu cho các công cụ Agri',
  pre_pay: 'Trả trước',
  cod: 'thanh toán khi giao hàng',
  after_30_days: '30 ngày kể từ ngày đặt hàng',
  after_45_days: '45 ngày kể từ ngày đặt hàng',
  after_60_days: '60 ngày kể từ ngày đặt hàng',
  after_90_days: '90 ngày kể từ ngày đặt hàng',
  after_120_days: '120 ngày kể từ ngày đặt hàng',
  after_180_days: '180 ngày kể từ ngày đặt hàng',
  after_365_days: '365 ngày kể từ ngày đặt hàng',
  other_term_offer: 'Điều khoản khác được cung cấp',
  other_term_offer_helper: 'Điều khoản khác được cung cấp',
  commision_model: 'Mô hình hoa hồng',
  all_transaction_commission: 'Tất cả hoa hồng giao dịch',
  only_new_customer_commission:
    'Chỉ có ủy ban khách hàng mới (tính toán chỉ dựa trên khách hàng mới, không bao gồm các khách hàng hiện tại được tuyên bố khi đăng ký, được hoàn trả cho tất cả hoa hồng sau 1 năm kể từ ngày đăng ký)',
  delivered_by_seller_3PL_appointed:
    'Được giao bởi người bán hoặc 3PL được chỉ định bởi người bán',
  total_no_of_customers: 'Tổng số khách hàng hiện tại',
  list_new_customers: 'Thêm khách hàng',
  min_day_delivery: 'Ngày giao hàng tối thiểu',
  max_day_delivery: 'Ngày giao hàng tối đa',
  type_of_seller: 'Kiểu người bán',
  individual: 'Cá nhân',
  juristic: 'Người quyết định',
  manufacturer_seller_activities: 'Nhà sản xuất',
  distributor_seller_activities: 'Nhà phân phối',
  importer_seller_activities: 'Nhà nhập khẩu',
  hear_on_web_app: 'Trang web/ứng dụng Agrimomo',
  hear_on_social_media: 'Mạng xã hội',
  hear_on_word_of_mouth: 'Truyền miệng',
  hear_on_local_authorities:
    'Chính quyền địa phương (ví dụ: Bộ Nông nghiệp, Hợp tác xã, v.v.)',
  submitted_time: 'Thời gian gửi',
  seller_time_submitted: 'Thời gian gửi của người bán',
  amm_time_submitted: 'Thời gian gửi của Agrimomo',
  farmshop_name_eng: 'Tên cửa hàng trang trại (tiếng Anh)',
  farmshop_name_local: 'Tên cửa hàng trang trại (ngôn ngữ địa phương)',
  farmshop_owner_name_eng: 'Tên chủ cửa hàng trang trại (tiếng Anh)',
  farmshop_owner_name_local: 'Tên chủ cửa hàng trang trại (tiếng Việt)',
  farmshop_type: 'Loại cửa hàng trang trại',
  manufacture: 'Nhà sản xuất',
  reseller: 'Đại lý bán lại',
  farmshop_mobile: 'Số điện thoại',
  farmshop_email: 'Email',
  farmshop_house_number: 'Số nhà',
  farmshop_street_name: 'Đường',
  farmshop_village_name: 'Làng',
  farmshop_sub_district_name: 'Quận',
  farmshop_district_name: 'Huyện',
  farmshop_province: 'Tỉnh/thành phố',
  farmshop_country: 'Quốc gia',
  farmshop_post_code: 'Mã vùng',
  farmshop_google_map: 'Google Map',
  farmshop_picture: 'Hình ảnh cửa hàng trang trại',
  farmshop_description: 'Mô tả chung cửa hàng trang trại',
  farmshop_feature_category: 'Danh mục nổi bật',
  farmshop_agent_level: 'Cấp độ đại lý',
  overview: 'Tổng quan',
  delivery_time: 'Thời gian giao hàng',
  documents: 'Tài liệu',
  survey: 'Khảo sát',
  financial_section: 'Phần tài chính',
  product_section: 'Phần sản phẩm',
  continue_later: 'Tiếp tục sau',
  submit: 'Nộp',
  trader_seller_activities: 'Thương nhân',
  province: 'Tỉnh/thành phố',
  sub_district: 'Phường/xã',
  seller_activities: 'Hoạt động của người bán',
  time_submitted: 'Thời gian người bán gửi',
  time_update: 'Thời gian người bán cập nhật',
  amm_time_verified: 'Thời gian AMM xác minh',
  amm_time_reject: 'Thời gian AMM từ chối',
  other_term_offered: 'Điều khoản đề xuất khác',
  seller_kyc: 'Người bán xác minh danh tính',
  last_updated_date: 'Ngày cập nhật lần cuối',
  feature_category: 'Ngành nghề đặc trưng',
  vendors: 'Nhà cung cấp',
  popular_products: 'Sản phẩm phổ biến',
  by: 'bởi',
  add: 'Thêm vào',
  featured_vendors: 'Các nhà cung cấp nổi bật',
  description: 'Mô tả',
  visit_store: 'Ghé thăm cửa hàng',
  we_found: 'Chúng tôi đã tìm thấy',
  item_for_you: 'sản phẩm cho bạn!',
  add_to_cart: 'Thêm vào giỏ hàng',
  availability: 'khả dụng',
  items_in_stock: 'Số lượng trong kho',
  additional_info: 'Thông tin bổ sung',
  vendor: 'Người bán',
  related_products: 'Sản phẩm tương tự',
  no_products_found: 'Không tìm thấy sản phẩm',
  contact_seller: 'Liên hệ với người bán',
  your_cart: 'Giỏ hàng của bạn',
  there_are: 'Có',
  products_in_your_cart: 'sản phẩm trong giỏ',
  clear_cart: 'Xóa hết sản phẩm',
  unit_price: 'Đơn giá',
  quantity: 'Số lượng',
  subtotal: 'Tổng phụ',
  remove: 'Bỏ',
  continue_shopping: 'Tiếp tục mua sắm',
  cart_totals: 'Tổng tiền',
  cart_subtotal: 'Tạm tính',
  shipping: 'Đang chuyển hàng',
  free_shipping: 'Miễn phí vận chuyển',
  total: 'Tổng cộng',
  proceed_to_payment: 'Tiến hành thanh toán',
  proceed_to_order: 'Tiến hành đặt hàng',
  checkout: 'Thanh toán',
  order_placement: 'Đặt hàng',
  current_user: 'Người dùng hiện tại',
  billing_details: 'Chi tiết thanh toán',
  shipping_details: 'Chi tiết vận chuyển',
  street_address: 'Địa chỉ',
  your_order: 'Đơn của bạn',
  no_product: 'Không có sản phẩm',
  create_order: 'Tạo đơn',
  place_order: 'Đặt hàng',
  account: 'Tài khoản',
  cart: 'Giỏ hàng',
  my_account: 'Tài khoản của tôi',
  orders: 'Đơn hàng',
  pos: 'POS',
  account_details: 'Chi tiết tài khoản',
  total_buy: 'Tổng số mua',
  revenue: 'Doanh thu',
  total_buy_quantity: 'Tổng số lượng mua',
  in_stock: 'Trong kho',
  my_orders: 'Đơn hàng của tôi',
  received: 'Tôi đã nhận đơn hàng',
  no_order: 'Không có đơn đặt hàng',
  display_name: 'Tên hiển thị',
  email_address: 'Email',
  new_password: 'Mật khẩu mới',
  product_detail: 'Chi tiết sản phẩm',
  order_price: 'Giá mua',
  selling_price: 'Giá bán',
  sold_quantity: 'Số lượng bán',
  expridate: 'Ngày hết hạn',
  update: 'Cập nhật',
  login: 'Đăng nhập',
  create_order_validate_blank_false:
    'Xin vui lòng điền đầy đủ vào những ô trống cần thiết',
  report: 'Báo cáo',
  Vendors_List: 'Danh sách nhà cung cấp',
  We_have: 'Chúng ta có',
  product_now: 'sản phẩm',
  vendors_now: 'nhà cung cấp',
  search_vendor_by_id: 'Tìm kiếm nhà cung cấp (theo tên hoặc ID) ...',
  amount: 'Số tiền',
  reject: 'Từ chối',
  show_banking_info: 'Hiển thị thông tin ngân hàng',
  pay: 'Trả',
  begin_delivery_soon: 'sẽ sớm được giao',
  dialog_reject: 'Bạn có chắc chắn muốn từ chối đơn đặt hàng này không?',
  dialog_cancel: 'Bạn có chắc là bạn muốn hủy đơn đặt hàng này?',
  delivered: 'Đơn hàng đang được giao',
  receipt: 'Biên lai',
  confirm_info:
    'Thông tin vận chuyển của tôi giống như thông tin thanh toán của tôi',
  brand_name_kyc: 'Mã người dùng',
  buyer_name_eng_helper: 'Tên người mua (tiếng Anh)',
  buyer_name_local_helper: 'Tên người mua (Ngôn ngữ địa phương) Người trợ giúp',
  buyer_name_eng: 'Tên người mua (tiếng Anh)',
  buyer_name_local: 'Tên người mua (tiếng Việt)',
  buyer_brand_logo: 'Logo thương hiệu người mua',
  general_description: 'Mô tả người mua',
  total_target_sales_on_amm: 'Tổng số mục tiêu mua trên AMM (mỗi tháng)',
  total_target_sales: 'Tổng số mục tiêu mua',
  manufacturer: 'Nhà sản xuất',
  type_of_buyer: 'Loại người mua',
  retailer_seller_activities: 'Nhà bán lẻ',
  farmer_seller_activities: 'Nông dân',
  wholesaler_seller_activities: 'Người bán buôn',
  copy_of_authorized_trading:
    'Bản sao của tài liệu giao dịch được ủy quyền, ví dụ: Giấy phép giao dịch phân bón, giấy phép giao dịch hóa chất nông nghiệp, giấy phép giao dịch hạt giống, giấy phép giao dịch thức ăn động vật, hợp đồng mua bán và bán hàng, hoặc hợp đồng bổ nhiệm đại lý bán hàng (tùy chọn)',
  buyer_activities: 'Hoạt động của người mua',
  land_area: 'Diện tích đất',
  update_buyer_kyc_sucessfully:
    'Cập nhật người mua xác minh danh tính thành công',
  general_description_helper: 'Tổng quan về sản phẩm',
  common_name: 'Tên thông dụng của sản phẩm',
  discount_info: 'Thông tin giảm giá',
  compliance_section: 'Giấy phép sản phẩm',
  date: 'Ngày Giao dịch',
  sub_product_type: 'Loại sản phẩm phụ',
  crop: 'Mùa vụ',
  animal: 'Thức ăn chăn nuôi',
  kyc: 'Người bán xác minh danh tính',
  mode_of_action: 'Phương thức hành động',
  manufacturer_name: 'tên nhà sản xuất',
  importer_name: 'Tên nhà nhập khẩu',
  distributor_name: 'Tên nhà phân phối',
  kyc_seller: 'Người bán xác minh danh tính',
  seller_management: 'Quản lý người bán',
  buyer_management: 'Quản lý người mua',
  kyc_seller_management: 'Người bán quản lý KYC',
  kyc_buyer_management: 'Người mua quản lý KYC',
  seller_user: 'Người bán người bán',
  buyer_user: 'Người dùng người mua',
  product_restore_confirm: 'Xác nhận khôi phục?',
  delete_user_confirm: 'Xác nhận xóa?',
  restore_user_confirm: 'Xác nhận khôi phục?',
  delete_buyer_confirm: 'Xác nhận xóa?',
  restore_buyer_confirm: 'Xác nhận khôi phục',
  seller_user_management: 'Quản lý người dùng người bán',
  buyer_user_management: 'Quản lý người dùng người mua',
  kyc_buyer: 'KYC người mua',
  fee_management: 'Quản lý phí toàn cầu',
  rate: 'Tỷ lệ',
  start_date: 'Ngày bắt đầu',
  end_date: 'Ngày cuối',
  added_by: 'Được thêm bởi',
  me: 'Tôi',
  admin: 'Quản trị viên',
  buyer_time_submitted: 'Thời gian người mua gửi',
  buyer_time_update: 'Thời gian người mua cập nhật',
  change_pass_des: 'Mật khẩu mới là mã OTP.',
  verify_number: 'Lấy mật khẩu mới',
  forgot_password: 'Quên mật khẩu?',
  seller_name: 'Tên người bán',
  update_time: 'Thời gian cập nhật',
  soft_delete: 'Soft Delete',
  seller_information: 'Thông tin người bán',
  discount_description: 'Chiết khấu thêm không vượt quá 90% “Giá không bao gồm phí logistic” và có thể được người bán tùy ý áp dụng từ việc đàm phán giá với người mua thông qua hệ thống Chat của Agrimomo. Do đó, điều này sẽ không làm giảm phí thị trường được tính từ "Giá không bao gồm phí hậu cần" ở trên.',
  sales_summary_report: 'Sales Summary Report',
  sales_detail_report: 'Sales Detail Report',
  report_year: 'Report Year'
};

export default words;
