import { setInfo } from 'actions';
import { confirmTokenApi } from 'apis';
import { useDispatch, useSelector } from 'react-redux';

export const useInitStoreAndConfirmToken = async router => {
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.session);
  try {
    await confirmTokenApi(router);
    if (!user.username) {
      const userName = window.localStorage.getItem('admin_agrimomo_username');
      dispatch(setInfo({ username: userName }));
    }
  } catch (error) {
    router.history.push('/auth/login');
  }
};
