/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';

import AuthLayout from './layouts/Auth';
import ErrorLayout from './layouts/Error';
import DashboardLayout from './layouts/Dashboard';
import OverviewView from './views/Overview';

const routes = [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/overview" />
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('views/Login'))
      },
      {
        path: '/auth/register',
        exact: true,
        component: lazy(() => import('views/Register'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/overview',
        exact: true,
        component: OverviewView
      },
      {
        path: '/product-management',
        exact: true,
        component: lazy(() => import('views/ProductManagement'))
      },
      {
        path: '/kyc-seller-management',
        exact: true,
        component: lazy(() => import('views/KYCSellerManagement'))
      },
      {
        path: '/kyc-buyer-management',
        exact: true,
        component: lazy(() => import('views/KYCBuyerManagement'))
      },
      {
        path: '/order-management',
        exact: true,
        component: lazy(() => import('views/OrderManagement'))
      },
      {
        path: '/price-by-gps-management',
        exact: true,
        component: lazy(() => import('views/PriceByGPSManagement'))
      },
      {
        path: '/order/:tab',
        exact: true,
        component: lazy(() => import('views/Order'))
      },
      {
        path: '/chat',
        exact: true,
        component: lazy(() => import('views/Chat'))
      },
      {
        path: '/chat/:id',
        exact: true,
        component: lazy(() => import('views/Chat'))
      },
      {
        path: '/kyc/:username',
        exact: true,
        component: lazy(() => import('views/KYC'))
      },
      {
        path: '/kyc-buyer/:username',
        exact: true,
        component: lazy(() => import('views/KYCBuyer'))
      },
      {
        path: '/order/:tab/:id',
        exact: true,
        component: lazy(() => import('views/Order'))
      },
      {
        path: '/farm-shop-management',
        exact: true,
        component: lazy(() => import('views/FarmShopManagement'))
      },
      {
        path: '/user-management',
        exact: true,
        component: lazy(() => import('views/UserManagement'))
      },
      {
        path: '/buyer-management',
        exact: true,
        component: lazy(() => import('views/BuyerManagement'))
      },
      {
        path: '/product/:tab',
        exact: true,
        component: lazy(() => import('views/Product'))
      },
      {
        path: '/product/:tab/:id',
        exact: true,
        component: lazy(() => import('views/Product'))
      },
      {
        path: '/user/:tab',
        exact: true,
        component: lazy(() => import('views/User'))
      },
      {
        path: '/user/:tab/:id',
        exact: true,
        component: lazy(() => import('views/User'))
      },
      {
        path: '/buyer/:tab',
        exact: true,
        component: lazy(() => import('views/Buyer'))
      },
      {
        path: '/buyer/:tab/:id',
        exact: true,
        component: lazy(() => import('views/Buyer'))
      },
      {
        path: '/farm-shop/:tab',
        exact: true,
        component: lazy(() => import('views/FarmShop'))
      },
      {
        path: '/farm-shop/:tab/:id',
        exact: true,
        component: lazy(() => import('views/FarmShop'))
      },
      {
        path: '/settings',
        exact: true,
        component: lazy(() => import('views/Settings'))
      },
      // {
      //   path: '/settings/:tab',
      //   exact: true,
      //   component: lazy(() => import('views/Settings'))
      // },
      {
        path: '/fee-management',
        exact: true,
        component: lazy(() => import('views/FeeManagement'))
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];

export default routes;
