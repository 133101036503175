import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  useMediaQuery,
  Grid,
  Typography,
  TablePagination
} from '@material-ui/core';

import { GenericMoreButton } from 'components';
import { t } from 'locale';
import { convertThaiDate } from 'utils/format';
import theme from 'theme';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 900
  },
  author: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(1)
  },
  tags: {
    '& > * + *': {
      marginLeft: theme.spacing(1)
    }
  },
  actions: {
    justifyContent: 'flex-end',
  },
  arrowForwardIcon: {
    marginLeft: theme.spacing(1)
  }
}));

const TableVertical = props => {
  const { className, list = [], ...rest } = props;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(event.target.value);
  };

  const data = React.useMemo(() => {
    return list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
  }, [page, list, rowsPerPage]);

  const classes = useStyles();
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardHeader action={<GenericMoreButton />} title={t('dashboard')} />
      <Divider />
      <CardContent className={classes.content}>
        <PerfectScrollbar options={{ suppressScrollY: true }}>
          {mobileDevice ? (
            <Table>
              <TableBody>
                {data?.map(project => (
                  <TableRow hover key={project.id}>
                    <TableCell>
                      <Grid container spacing={1} direction="column">
                        <Grid item>
                          <Typography variant="h5" color="secondary">
                            {t('type')}:
                          </Typography>
                          <Typography variant="body">{t(project.type)}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="h5" color="secondary">
                            {t('log')}:
                          </Typography>
                          <Typography variant="body">{project.log}</Typography>
                        </Grid>
                        <Grid item>
                          <Typography variant="h5" color="secondary">
                            {t('created_time')}:
                          </Typography>
                          <Typography variant="body">
                            {convertThaiDate(project.created_time)}
                          </Typography>
                        </Grid>
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          ) : (
            <div className={classes.inner}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>{t('type')}</TableCell>
                    <TableCell>{t('log')}</TableCell>
                    <TableCell>{t('created_time')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map(project => (
                    <TableRow hover key={project.id}>
                      <TableCell>{t(project.type)}</TableCell>
                      <TableCell>{project.log}</TableCell>
                      <TableCell>
                        {convertThaiDate(project.created_time)}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          )}
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={list.length}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
          labelRowsPerPage="Row"
        />
      </CardActions>
    </Card>
  );
};

TableVertical.propTypes = {
  className: PropTypes.string
};

export default TableVertical;
