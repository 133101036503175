import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Button, Dialog, colors, Card } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { t } from 'locale';

const useStyles = makeStyles(theme => ({
  root: {
    // width: 720
  },
  header: {
    padding: theme.spacing(3),
    maxWidth: 720,
    margin: '0 auto'
  },
  actionsHeader: {
    backgroundColor: colors.grey[100],
    display: 'flex',
    justifyContent: 'flex-end',
    minHeight: 30
  },
  content: {
    padding: theme.spacing(0.5),
    maxWidth: 720,
    margin: '0 auto',
    textAlign: 'center'
  },
  image: {
    height: '100%',
    width: '100%'
  },
  buttonClose: {
    padding: 0,
    minWidth: 0,
    borderRadius: '50%',
    margin: theme.spacing(0.5)
  },
  actionsFooter: {
    backgroundColor: colors.grey[100],
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(1)
  },
  deleteButton: {
    backgroundColor: colors.red[600],
    color: colors.common.white,
    width: '50%'
  }
}));

const ImageDetail = ({
  open,
  imageIndex,
  onClose,
  image,
  className,
  onRemove,
  ...rest
}) => {
  const classes = useStyles();

  const renderFile = filePath => {
    if (!filePath) return null;
    const fileType = filePath.split('.').pop();
    return fileType === 'pdf' ? (
      <div>
        <iframe
          id="pdf-js-viewer"
          src={filePath}
          title="webviewer"
          frameBorder="0"
          width="500"
          height="600"
        />
      </div>
    ) : (
      <img alt="" className={classes.image} src={filePath} />
    );
  };

  return (
    <Dialog maxWidth="lg" onClose={onClose} open={open}>
      <div {...rest} className={clsx(classes.root, className)}>
        <div className={classes.actionsHeader}>
          <Button
            className={classes.buttonClose}
            onClick={onClose}
            variant="contained">
            <CancelIcon />
          </Button>
        </div>
        <Card {...rest} className={clsx(classes.content, className)}>
          {renderFile(image)}
        </Card>
        {onRemove && (
          <div className={classes.actionsFooter}>
            <Button
              className={classes.deleteButton}
              variant="contained"
              onClick={() => {
                onRemove(imageIndex);
                onClose();
              }}>
              {t('delete')}
            </Button>
          </div>
        )}
      </div>
    </Dialog>
  );
};

ImageDetail.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string,
  onClose: PropTypes.func,
  onRemove: PropTypes.func,
  open: PropTypes.bool.isRequired
};

export default ImageDetail;
